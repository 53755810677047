<template>
  <div class="note-editor">
    <div class="form-group">
      <div v-if="page && !isHome">
        <b-row>
          <b-col>
            <b-form-checkbox id="attachToPage" v-model="attachToPage">
              Attacher cette note à la page
              {{ page }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <hr />
      </div>
      <b-row>
        <b-col>
          <b-form-group label-for="noteTitle" label="Titre">
            <b-input type="text" name="noteTitle" v-model="noteTitle" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-for="noteBody" label="Message">
            <b-textarea name="noteBody" v-model="noteBody"></b-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Priorité" label-for="priority">
            <b-form-select v-model="notePriorityId" id="priority">
              <b-select-option v-for="elt of notePriorities" :key="elt.id" :value="elt.id">
                {{ elt.name }}
              </b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Destinataires"
            label-for="note-dest"
            description="Les notes sont visibles de tous. Elles sont épinglées sur l'accueil des destinataires"
          >
            <check-box-select
              id="note-dest"
              :choices="staff"
              :initial-value="meMyMyselfStaff"
              :name-callback="getUserName"
              @changed="selectedStaff = $event.choices"
              inline
            >
            </check-box-select>
            <hr />
            <check-box-select
              id="note-support"
              :choices="support"
              :initial-value="meMyMyselfSupport"
              :name-callback="getUserName"
              @changed="selectedSupport = $event.choices"
              inline
            >
            </check-box-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="buttons-bar">
        <b-col>
          <a class="btn btn-secondary" href @click.prevent="onCancel">Annuler</a>
        </b-col>
        <b-col class="text-right">
          <a class="btn btn-primary" href @click.prevent="onSave" :class="{ disabled: noteTitle === '', }">
            {{ saveTitle }}
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import { makeStaffUser } from '@/types/users'
import { BackendApi } from '@/utils/http'
import { makeNote } from '@/types/notes'
import { existsIn } from '@/utils/arrays'

export default {
  name: 'note-editor',
  components: { CheckBoxSelect, },
  mixins: [BackendMixin],
  props: {
    home: {
      type: Boolean,
      default: false,
    },
    note: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      noteTitle: '',
      noteBody: '',
      notePriorityId: 2,
      notePriorities: [
        makeChoice({ id: 2, name: 'Normal', }),
        makeChoice({ id: 1, name: 'Haute', }),
        makeChoice({ id: 3, name: 'Basse', })
      ],
      staff: [],
      selectedStaff: [],
      support: [],
      selectedSupport: [],
      attachToPage: true,
      page: '',
      isHome: false,
    }
  },
  computed: {
    saveTitle() {
      return this.note ? 'Enregistrer' : 'Ajouter'
    },
    navTitle() {
      return store.getters.navTitle
    },
    navIcon() {
      return store.getters.navIcon
    },
    currentUserEmail() {
      return store.getters.userEmail
    },
    meMyMyselfStaff() {
      if (this.note) {
        return this.staff.filter(elt => existsIn([elt.id], this.note.recipients))
      } else {
        return this.staff.filter(elt => elt.email === this.currentUserEmail)
      }
    },
    meMyMyselfSupport() {
      if (this.note) {
        return this.support.filter(elt => existsIn([elt.id], this.note.recipients))
      } else {
        return this.support.filter(elt => elt.email === this.currentUserEmail)
      }
    },
  },
  mounted() {
    this.loadStaff()
    this.init()
  },
  watch: {
    note: function() {
      this.init()
    },
  },
  methods: {
    getUserName(item) {
      return item.fullName()
    },
    init() {
      this.isHome = window.location.pathname === '/'
      if (this.note) {
        this.noteTitle = this.note.title
        this.noteBody = this.note.body
        this.notePriorityId = this.note.priority
        if (this.note.page) {
          this.page = this.note.page.name
          this.attachToPage = true
        } else {
          this.page = ''
          this.attachToPage = false
        }
      } else {
        this.noteTitle = ''
        this.noteBody = ''
        this.notePriorityId = 2
        this.selectedStaff = []
        this.selectedSupport = []
        this.page = this.navTitle
        this.attachToPage = !this.isHome
      }
    },
    async onSave() {
      if (this.noteTitle) {
        let url = '/notes/api/notes/'
        if (this.note) {
          url += this.note.id + '/'
        }
        const data = {
          title: this.noteTitle,
          body: this.noteBody,
          priority: this.notePriorityId,
          recipients: this.selectedStaff.map(elt => elt.id).concat(
            this.selectedSupport.map(elt => elt.id)
          ),
        }
        if (!this.note && (this.attachToPage && !this.isHome)) {
          data.page = {
            path: window.location.pathname,
            name: this.navTitle,
            icon: this.navIcon,
          }
        }
        if (this.note && !this.attachToPage) {
          data.page = null
        }
        const backendApi = new BackendApi(this.note ? 'patch' : 'post', url)
        try {
          const resp = await backendApi.callApi(data)
          if (this.note) {
            this.$emit('note-saved', makeNote(resp.data))
          } else {
            this.$emit('note-added', makeNote(resp.data))
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadStaff() {
      this.staff = []
      this.support = []
      let backendApi = new BackendApi('get', '/users/api/users/?include_superuser=1&notes=1')
      try {
        let resp = await backendApi.callApi()
        this.staff = resp.data.map(elt => makeStaffUser(elt)).filter(elt => elt.isActive && !elt.isSuperUser)
        this.support = resp.data.map(elt => makeStaffUser(elt)).filter(elt => elt.isActive && elt.isSuperUser)
        this.selectedStaff = this.meMyMyselfStaff
        this.selectedSupport = this.meMyMyselfSupport
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onCancel() {
      this.$emit('cancelled')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
