<template>
  <div v-if="hasPerm('payments.change_invoice')" style="display: inline-block">
    <loading-gif :short="true" :loading-name="loadingName"></loading-gif>
    <send-email-modal
      :name="id"
      :title="confirmSendingTitle"
      :text="confirmSendingText"
      :init-subject="emailSubject"
      :init-from-email="fromEmail"
      :choices="invoiceChoices"
      @confirmed="onConfirmSending($event)"
      @choices-changed="onChoicesChanged($event)"
    ></send-email-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import SendEmailModal from '@/components/Modals/SendEmailModal.vue'
import store from '@/store'
import { existsIn } from '@/utils/arrays'

export default {
  name: 'invoices-sender',
  components: {
    LoadingGif,
    SendEmailModal,
  },
  mixins: [
    BackendMixin
  ],
  props: {
    id: String,
    invoices: Array,
    canSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingName: 'invoices-sender',
      selectedChoices: [],
    }
  },
  computed: {
    emails() {
      if (this.entity) {
        return this.entity.getParentsContactIndividuals().map(
          elt => elt.email
        ).filter(elt => elt !== '')
      }
      return []
    },
    emailSubject() {
      return 'Facturation'
    },
    fromEmail() {
      return store.getters.userEmail
    },
    confirmSendingTitle() {
      const invoices = this.selectedInvoices
      return 'Envoi de facture' + ((invoices.length > 1) ? 's' : '')
    },
    confirmSendingText() {
      let text = ''
      const invoices = this.selectedInvoices
      if (invoices.length === 1) {
        const invoice = invoices[0]
        text = 'Souhaitez-vous envoyer la '
        if (invoice.running) {
          text += invoice.getSafeNumber()
        } else {
          text += 'facture ' + invoice.getNumber()
        }
      } else if (invoices.length > 1) {
        text = 'Souhaitez-vous envoyer les ' + invoices.length + ' factures suivantes: '
        text += invoices.map(invoice => invoice.getSafeNumber()).join(', ')
      }
      if (text) {
        text += ' depuis le serveur millibase.net?\nL\'envoi ne sera pas immédiat et mis en file d\'attente'
      }
      return text
    },
    invoiceChoices() {
      if (this.canSelect) {
        return this.invoices.map(elt => {
          return {
            id: elt.id,
            name: elt.getSafeNumber(),
          }
        })
      } else {
        return []
      }
    },
    selectedInvoices() {
      if (this.canSelect) {
        const selectedIds = this.selectedChoices.map(elt => elt.id)
        return this.invoices.filter(invoice => existsIn([invoice.id], selectedIds))
      } else {
        return this.invoices
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onConfirmSending(event) {
      this.startLoading(this.loadingName)
      const invoices = this.selectedInvoices
      const invoiceIds = invoices.map(elt => elt.id)
      let url = '/api/invoice-email-batch/'
      const data = {
        invoices: invoiceIds,
        subject: event.subject,
        body: event.body,
        sender: event.fromEmail,
      }
      const backendApi = new BackendApi('post', url)
      try {
        const resp = await backendApi.callApi(data)
        const sentCount = resp.data.sent.length
        const notSentCount = resp.data['not_sent'].length
        if (sentCount) {
          let text = ''
          if (sentCount > 1) {
            text += '' + sentCount + ' emails ont été envoyés'
          } else {
            text += 'Un email a été envoyé'
          }
          await this.addSuccess(text)
        }
        if (notSentCount) {
          let text = 'Adresse email manquante ou pas de contact par email: '
          if (notSentCount > 1) {
            text += '' + notSentCount + ' emails n\'ont pas pu être envoyés'
          } else {
            text += 'Un email n\'a pas pu être envoyé'
          }
          await this.addWarning(text)
        }
        this.$emit('done', { invoices: this.invoices, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    onChoicesChanged(event) {
      this.selectedChoices = event.choices
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
