
export class Choice {
  constructor(
    public id: number,
    public name: string,
    public extra: any = null
  ) {
  }
}

export function makeChoice(jsonData: any = null): Choice {
  if (!jsonData) {
    jsonData = {}
  }
  return new Choice(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.extra || null
  )
}

export class DateRange {
  constructor(
    public id: number,
    public name: string,
    public startDate: Date|null,
    public endDate: Date|null
  ) {
  }
}

export function makeDateRange(jsonData: any = null): DateRange {
  if (!jsonData) {
    jsonData = {}
  }
  return new DateRange(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.start_date || null,
    jsonData.end_date || null
  )
}

export class Place {
  constructor(
    public id: number,
    public name: string,
    public backgroundColor: string,
    public textColor: string,
    public alwaysAvailable: boolean
  ) {
  }
}

export function makePlace(jsonData: any = null): Place {
  if (!jsonData) {
    jsonData = {}
  }
  return new Place(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.background_color || '',
    jsonData.text_color || '',
    !!jsonData.always_available
  )
}
