<template>
  <div class="notes-sidebar sidebar" v-if="canViewNotes">
    <b-sidebar
      id="sidebar-notes"
      left shadow width="30%" no-header
      @shown="onSideBarOpened()"
      @hidden="onSideBarClosed()"
    >
      <template #default="{ hide }">
        <div class="px-3 py-2">
          <b-row>
            <b-col>
              <b-form-checkbox
                id="include-archived-notes"
                class="small-text"
                v-model="includeArchives"
                :disabled="tab !== 'page'"
              >
                Inclure les archives
              </b-form-checkbox>
            </b-col>
            <b-col cols="1" class="text-right">
              <i class="fa fa-close a-like" @click="hide"></i>
            </b-col>
          </b-row>
          <div class="sidebar-header-bar">
            <a href @click.prevent="loadPageNotes()" :class="{ active: tab === 'page', }" v-if="hasPageTab">
              <i class="fa fa-sticky-note"></i>
              <counter-label :counter="notesOnPage" label="sur la page" label-n="sur la page"></counter-label>
            </a>
            <a href @click.prevent="loadUnreadNotes()" :class="{ active: tab === 'unread', }">
              <i class="fa fa-receipt"></i>
              <counter-label :counter="unreadNotes" label="non-lue"></counter-label>
            </a>
            <a href @click.prevent="loadPinnedNotes()" :class="{ active: tab === 'pinned', }">
              <i class="fa fa-map-pin"></i>
              <counter-label :counter="pinnedNotes" label="épinglée"></counter-label>
            </a>
            <a href @click.prevent="viewSearch()" :class="{ active: tab === 'search', }">
              <i class="fa fa-search"></i>
              recherche
            </a>
          </div>
          <ul class="app-menu">
            <li>
              <b-row>
                <b-col cols="4">
                  <b><i class="fa fa-sticky-note"></i> Notes</b>
                </b-col>
                <b-col cols="4" class="text-right">
                  <router-link :to="{ name: 'notes', }" class="btn btn-xs btn-secondary">
                    <i class="fa fa-th-list"></i> Toutes les notes
                  </router-link>
                </b-col>
                <b-col cols="4" class="text-right">
                  <a
                    class="btn btn-xs btn-secondary"
                    href
                    @click.prevent="toggleEditMode(true)"
                    v-if="canAddNote && !editMode"
                  >
                    <i class="fas fa-file-pen"></i> Nouvelle note
                  </a>
                </b-col>
              </b-row>
            </li>
          </ul>
          <div v-if="editMode">
            <note-editor
              :note="editedNote"
              @note-added="onNoteAdded"
              @note-saved="onNoteSaved"
              @cancelled="toggleEditMode(false)"
            >
            </note-editor>
          </div>
          <div v-else>
            <div v-if="tab === 'search'" style="margin-bottom: 5px;">
              <b-form @submit.prevent="onSearchNotes()">
                <b-input-group class="mt-3 input-group-primary sm">
                  <b-form-input
                    id="search-notes"
                    type="search"
                    placeholder="Entrez un mot du sujet"
                    v-model="searchText"
                    autocomplete="off"
                    :class="{ filled: searchText }"
                  >
                  </b-form-input>
                  <b-input-group-append>
                    <b-button type="submit" variant="primary">
                      <i class="fa fa-search"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </div>
            <loading-gif :loading-name="loadingName"></loading-gif>
            <note-items
              :items="notes"
              v-if="(tab !== 'search') || (notes.length > 0)"
              :side="true"
              :home="tab === 'pinned'"
              @item-pin="onItemPin($event)"
              @item-delete="onItemDelete($event)"
              @item-edit="onEditNote($event)"
              @update="refresh($event)"
            ></note-items>
            <div v-if="(tab === 'search') && searched && notes.length === 0" class="empty-text">
              Aucun résultat ne correspond à la recherche {{ searched }}
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
    <confirm-modal
      name="delete-note-sidebar"
      icon="fa fa-trash"
      title="Suppression d'une note"
      :text="confirmDeleteText"
      :object="selectedNote"
      @confirmed="onDeleteNote"
      ok-variant="danger"
    >
    </confirm-modal>
    <confirm-modal
      name="pin-note-sidebar"
      icon="fa fa-map-pin"
      :title="pinNoteTitle"
      :text="pinNoteText"
      :object="selectedNote"
      @confirmed="onPinNote"
    >
    </confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import store from '@/store'
import NoteEditor from '@/components/Notes/NoteEditor'
import LoadingGif from '@/components/Controls/LoadingGif'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import NoteItems from '@/components/Notes/NoteItems.vue'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { BackendApi } from '@/utils/http'
import { makeNote } from '@/types/notes'
import CounterLabel from '@/components/Controls/CounterLabel.vue'

export default {
  name: 'notes-sidebar',
  components: { CounterLabel, LoadingGif, NoteEditor, NoteItems, ConfirmModal, },
  mixins: [BackendMixin],
  props: {
  },
  data() {
    return {
      editMode: false,
      notesOnPage: 0,
      unreadNotes: 0,
      pinnedNotes: 0,
      tab: 'page',
      notes: [],
      loadingName: 'side-notes-loading',
      selectedStaff: [],
      selectedNote: null,
      includeArchives: false,
      editedNote: null,
      searchText: '',
      searched: '',
      hasPageTab: true,
    }
  },
  computed: {
    confirmDeleteText() {
      if (this.selectedNote) {
        if (this.selectedNote.archived) {
          return (
            '"' + this.selectedNote.title + '"\n\n' +
            'Voulez-vous supprimer définitivement cette note?'
          )
        } else {
          return (
            '"' + this.selectedNote.title + '"\n\n' +
            'Voulez-vous archiver cette note pour tous les destinataires?'
          )
        }
      }
      return ''
    },
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    canViewNotes() {
      return this.isAuthenticated && this.hasPerm('notes.view_note')
    },
    canAddNote() {
      return this.isAuthenticated && this.hasPerm('notes.add_note')
    },
    pinNoteTitle() {
      if (this.selectedNote) {
        if (this.selectedNote.isPinned) {
          return 'Ne plus épingler la note'
        } else {
          return 'Épingler la note'
        }
      }
      return ''
    },
    pinNoteText() {
      if (this.selectedNote) {
        if (this.selectedNote.isPinned) {
          return (
            '"' + this.selectedNote.title + '"\n\n' +
            'Voulez-vous désépingler cette note de votre page d\'accueil?'
          )
        } else {
          return (
            '"' + this.selectedNote.title + '"\n\n' +
            'Voulez-vous épingler cette note de votre page d\'accueil?'
          )
        }
      }
      return ''
    },
  },
  watch: {
    includeArchives: function() {
      this.loadPageNotes()
    },
  },
  methods: {
    ...mapMutations(['setNotesUpdated']),
    ...mapActions(['addError']),
    async onSideBarOpened() {
      const pathName = window.location.pathname
      this.hasPageTab = pathName !== '/'
      if (!this.hasPageTab && this.tab === 'page') {
        this.tab = 'pinned'
      }
      await this.loadNotesCount()
      if (this.tab === 'page') {
        this.loadPageNotes()
      } else if (this.tab === 'unread') {
        this.loadUnreadNotes()
      } else if (this.tab === 'pinned') {
        this.loadPinnedNotes()
      } else if (this.tab === 'search') {
        this.notes = []
      }
    },
    onSideBarClosed() {
      this.notes = []
      this.editMode = false
    },
    toggleEditMode(value) {
      this.editMode = value
      this.editedNote = null
    },
    loadPageNotes() {
      this.tab = 'page'
      this.loadNotes('path=' + window.location.pathname)
    },
    loadUnreadNotes() {
      this.tab = 'unread'
      this.loadNotes('unread=1')
    },
    loadPinnedNotes() {
      this.tab = 'pinned'
      this.loadNotes('pinned=1')
    },
    viewSearch() {
      this.tab = 'search'
      this.notes = []
      this.searchResults = false
    },
    async loadNotes(filter) {
      let url = '/notes/api/notes/?' + filter
      if (this.includeArchives) {
        url += '&include_archives=1'
      }
      this.startLoading(this.loadingName)
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.notes = resp.data.results.map(elt => makeNote(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadNotesCount() {
      const url = '/notes/api/notes/count/?path=' + window.location.pathname
      this.startLoading(this.loadingName)
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.notesOnPage = resp.data.page
        this.unreadNotes = resp.data.unread
        this.pinnedNotes = resp.data.pinned
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    // onNotesUpdated() {
    //   this.notesUpdated = true
    //   this.setNotesUpdated(true)
    //   const that = this
    //   this.$nextTick(
    //     () => {
    //       that.setNotesUpdated(false)
    //     }
    //   )
    // },
    onNoteAdded(note) {
      this.editMode = false
      this.notes = [note].concat(this.notes)
      this.$emit('update')
      this.loadNotesCount()
    },
    onEditNote(event) {
      this.editedNote = event
      this.editMode = true
    },
    onNoteSaved(editedNote) {
      const index = this.notes.map(elt => elt.id).indexOf(editedNote.id)
      if (index >= 0) {
        this.notes[index] = editedNote
      }
      this.notes = [].concat(this.notes)
      this.editMode = false
      this.editedNote = null
      this.$emit('update')
    },
    onItemPin(event) {
      this.selectedNote = event
      this.$bvModal.show('bv-confirm-modal:pin-note-sidebar')
    },
    onItemDelete(event) {
      this.selectedNote = event
      this.$bvModal.show('bv-confirm-modal:delete-note-sidebar')
    },
    async onDeleteNote() {
      if (this.selectedNote) {
        this.startLoading(this.loadingName)
        let url = '/notes/api/notes/' + this.selectedNote.id + '/'
        if (this.selectedNote.archived) {
          url += '?include_archives=1'
        }
        const backendApi = new BackendApi('delete', url)
        try {
          await backendApi.callApi()
          this.notes = this.notes.filter(elt => elt.id !== this.selectedNote.id)
          this.$emit('update')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.selectedNote = null
        this.endLoading(this.loadingName)
      }
      await this.loadNotesCount()
    },
    async onPinNote() {
      if (this.selectedNote) {
        this.startLoading(this.loadingName)
        let url = '/notes/api/pin-note/' + this.selectedNote.id + '/'
        let backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi({ 'pin_note': !this.selectedNote.isPinned, })
          this.refresh(
            {
              note: makeNote(resp.data),
            }
          )
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.selectedNote = null
        this.endLoading(this.loadingName)
      }
      await this.loadNotesCount()
    },
    async refresh(event) {
      const index = this.notes.map(elt => elt.id).indexOf(event.note.id)
      if (index >= 0) {
        this.notes[index] = event.note
        this.notes = [].concat(this.notes)
      }
      await this.loadNotesCount()
    },
    async onSearchNotes() {
      this.searched = this.searchText
      if (this.searched) {
        await this.loadNotes('search=' + this.searched)
      } else {
        this.notes = []
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.a-like {
  cursor: pointer;
}
.a-like:hover {
  color: #666;
}
.sidebar-header-bar > a {
  background: #eee;
  color: #222 !important;
  font-size: 12px;
  padding: 2px 10px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 4px;
}
.sidebar-header-bar > a.active {
  background: #222;
  color: #fff !important;
}
.dark-mode {
  .sidebar {
    color: #fff;
  }
  .a-like {
    color: #fff;
  }
  .a-like:hover {
    color: #ccc;
  }
}
</style>
