<template>
  <span class="reassign-credit-overpaids" v-if="hasPerm('payments.change_credit')">
    <b-modal
      dialog-class="modal-md"
      :id="modalId"
      @ok.prevent="onSave"
      :ok-disabled="formInvalid"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Changer"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-exchange-alt"></i>
          Affectation analytique d'un trop-perçu
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="credit" style="padding: 10px; background: #222; color: #fff; ">
        {{ credit.source }}
        <analytics-detail-view
          :analytics="credit.fromAnalytics"
          from
          label="Source"
        >
        </analytics-detail-view>
      </div>
      <b-row>
        <b-col>
          <b-form-group
            label="Réaffecter l'avoir sur le compte analytique" label-for="newAnalytic"
            description="L'avoir sera réaffecté sur cet analytique"
          >
            <b-select v-model="newAnalytic" id="newAnalytic">
              <b-select-option :value="elt" v-for="elt of analyticAccounts" :key="elt.id">
                {{ elt.getLabel() }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import AnalyticsDetailView from '@/components/Accounting/AnalyticsDetailView.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeAnalyticAccount } from '@/types/payments'

export default {
  name: 'ReassignCreditOverpaidModal',
  components: { AnalyticsDetailView, },
  mixins: [BackendMixin],
  props: {
    credit: Object,
  },
  data() {
    return {
      errorText: '',
      newAnalytic: null,
      analyticAccounts: [],
    }
  },
  watch: {
    credit: function() {
    },
  },
  mounted() {
  },
  computed: {
    formInvalid() {
      return (this.credit) && (this.newAnalytic === null)
    },
    modalId() {
      return 'bv-reassign-credit-overpaid-modal'
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    init() {
      this.newAnalytic = null
      this.analyticAccounts = []
      if (this.credit) {
        this.loadAnalytics()
      }
    },
    async loadAnalytics() {
      this.errorText = ''
      let url = '/api/analytic-accounts/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.analyticAccounts = resp.data.map(makeAnalyticAccount)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    async onSave() {
      this.errorText = ''
      if (!this.formInvalid) {
        let url = '/api/reassign-credit-overpaid/'
        const backendApi = new BackendApi('post', url)
        try {
          const data = {
            'credit_overpaid': this.credit.id,
            'analytic_account': this.newAnalytic.id,
          }
          await backendApi.callApi(data)
          const text = 'L\'avoir a été réaffecté'
          await this.addSuccess(text)
          this.$bvModal.hide(this.modalId)
          this.$emit('done')
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
  },
}
</script>

<style scoped>
</style>
