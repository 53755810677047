<template>
  <span v-if="hasPerm('payments.change_invoice')">
    <loading-gif :loading-name="loadingName" short></loading-gif>
    <span v-b-tooltip="tooltipMessage" v-if="!isLoading(loadingName)">
      <a
        class="btn btn-primary btn-sm"
        href
        @click.prevent="sendInvoice"
        v-if="!advancedSend"
        :class="{ disabled: emails.length === 0, displayBlock: displayBlock, }"
        :style="smallLink ? 'font-size: 10px' : ''"
      >
        <i class="fa fa-send"></i>&nbsp;Envoyer
      </a>
      <b-dropdown
        v-else
        text="Envoyer"
        :style="smallLink ? 'font-size: 10px' : ''"
        :class="{ disabled: emails.length === 0, displayBlock: displayBlock, }"
        variant="primary" size="sm"
      >
        <b-dropdown-item @click.prevent="sendInvoice">
          <i class="fa fa-send"></i> Depuis votre logiciel
        </b-dropdown-item>
        <b-dropdown-item @click.prevent="sendInvoiceFromServer">
          <i class="fa fa-plane-departure"></i> Depuis millibase.net
        </b-dropdown-item>
      </b-dropdown>
    </span>
  </span>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'
import { makeEntity } from '@/types/people'
import { makeInvoice } from '@/types/payments'

export default {
  name: 'invoice-send',
  components: {
    LoadingGif,
  },
  mixins: [
    BackendMixin
  ],
  props: {
    invoice: Object,
    invoiceEntity: {
      type: [Object, null],
      default: null,
    },
    displayBlock: {
      type: Boolean,
      default: false,
    },
    smallLink: {
      type: Boolean,
      default: false,
    },
    advancedSend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingName: 'invoiceSend',
      entity: null,
      invoiceObj: null,
    }
  },
  computed: {
    separator() {
      return window.localStorage.getItem('emailSeparator') || ','
    },
    emails() {
      if (this.entity) {
        return this.entity.getParentsContactIndividuals().map(
          elt => elt.email
        ).filter(elt => elt !== '')
      }
      return []
    },
    tooltipMessage() {
      if (this.emails.length) {
        return this.emails.join(this.separator)
      } else {
        return 'Aucun email n\'est configuré pour cette famille'
      }
    },
  },
  mounted() {
    this.loadEntity()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async sendInvoice() {
      if (this.emails) {
        this.startLoading(this.loadingName)
        try {
          const backendApi = new BackendApi('get', '/api/invoice-email/' + this.invoice.id + '/')
          const resp = await backendApi.callApi()
          let subject = resp.data.subject
          let body = resp.data.body
          let mailto = 'mailto:' + this.emails.join(this.separator)
          mailto += '?subject=' + subject
          mailto += '&body=' + body
          mailto = encodeURI(mailto)
          window.location.href = mailto
          await this.changeStatus()
          this.$emit('sent', { invoice: this.invoice, })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    sendInvoiceFromServer() {
      this.$emit('send', { invoice: this.invoice, })
    },
    async changeStatus() {
      try {
        const backendApi = new BackendApi('post', '/api/invoice-detail/' + this.invoice.id + '/')
        const resp = await backendApi.callApi({ is_sent: true, })
        this.$emit('updated', makeInvoice(resp.data))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadEntity() {
      if (this.invoiceEntity) {
        this.entity = this.invoiceEntity
      } else {
        if (this.invoice.entity) {
          this.startLoading(this.loadingName)
          let entityId = this.invoice.entity.id
          let isFamily = !!this.invoice.entity.family
          try {
            let url = '/api/people/contacts/' + entityId + '/'
            if (isFamily) {
              url = '/api/families/families/' + entityId + '/'
            }
            const backendApi = new BackendApi('get', url)
            const resp = await backendApi.callApi()
            this.entity = makeEntity(resp.data)
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
          this.endLoading(this.loadingName)
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.displayBlock {
  display: block;
  margin-bottom: 2px;
}
</style>
