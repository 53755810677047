<template>
  <div
    class="add-family" v-if="isAuthenticated && hasPerm('families.add_familyentity')"
  >
    <a href @click.prevent="showScreen()">
      <i
        class="fa fa-person-circle-plus add-family-icon right-bar-icon"
        title="Ajout d'une famille"
        v-b-tooltip.left
      ></i>
    </a>
    <div class="add-family-screen-content" v-show="isScreenShown" :style="windowsStyle">
      <b-row class="close-screen-line">
        <b-col>
          <h3><i class="fa fa-person-circle-plus"></i> Ajout d'une famille</h3>
        </b-col>
        <b-col cols="3" class="text-right">
          <i
            class="fa fa-info-circle"
            v-b-tooltip
            title="Ctrl+Esc: cacher, Ctrl+Espace: afficher"
          >
          </i>&nbsp;
          <a class="close-link" href @click.prevent="isScreenShown = false"><i class="fa fa-close"></i></a>
        </b-col>
      </b-row>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div class="add-family-form" v-if="!isLoading(loadingName) && isScreenShown">
        <b-row>
          <b-col>
            <div><b>Membres de la famille</b></div>
            <div class="add-family-item">
              <individual-identity
                :is-family="true"
                :entity="family.entity"
                :member="firstMember"
                :add-mode="true"
                :force-save="forceIndividualSave"
                :upper-case-name="upperCaseName"
                @is-valid-changed="individualIsValid = $event.isValid"
                @last-name-changed="forceEntityName = $event.individual.lastName"
                @change="onIndividualSaved"
                @failed="onIndividualFailed"
                dark
              >
              </individual-identity>
            </div>
          </b-col>
          <b-col>
            <div><b>Famille</b></div>
            <div class="add-family-item">
              <entity-detail
                :entity="family.entity"
                :show-all="true"
                :add-mode="true"
                :initial-edit-mode="true"
                :is-family="true"
                :force-entity-name="forceEntityName"
                :force-save="forceEntitySave"
                @uppercase="onUpperCaseChanged"
                @is-valid-changed="entityIsValid = $event.isValid"
                @saved="onEntitySaved"
                dark
              >
              </entity-detail>
            </div>
          </b-col>
        </b-row>
        <b-row class="buttons-bar">
          <b-col class="text-right">
            <a
              href
              @click.prevent="onSave()"
              class="btn btn-primary"
              :class="{ disabled: !isValid, }"
            >
              Enregistrer
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif'
import EntityDetail from '@/components/Entity/EntityDetail'
import IndividualIdentity from '@/components/Individual/IndividualIdentity'
import router from '@/router'
import store from '@/store'
import { makeFamily } from '@/types/families'
import { makeEntityMembership, makeEntityRole, makeGender } from '@/types/people'
import { BackendApi } from '@/utils/http'
import { makeFieldsGroup } from '@/types/fields'

export default {
  name: 'AddFamilyScreen',
  components: { LoadingGif, EntityDetail, IndividualIdentity, },
  mixins: [BackendMixin],
  data() {
    return {
      isScreenShown: false,
      family: makeFamily(),
      firstMember: makeEntityMembership(),
      loadingName: 'add-family-screen',
      otherMembers: [],
      forceEntityName: '',
      entityIsValid: false,
      individualIsValid: false,
      forceEntitySave: false,
      forceIndividualSave: false,
      loaded: false,
      upperCaseName: false,
    }
  },
  created() {
    window.addEventListener('keydown', this.keyDown)
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    windowsStyle() {
      return { 'min-height': '100vw', }
    },
    showAddFamily() {
      return store.getters.showAddFamily
    },
    canView() {
      return this.hasPerm('families.view_familyentity')
    },
    isValid() {
      return this.individualIsValid && this.entityIsValid
    },
  },
  watch: {
    showAddFamily() {
      if (this.showAddFamily) {
        this.showScreen()
        store.commit('showAddFamily', false)
      }
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['setGenders', 'setEntityRoles', 'setFieldGroups', 'startLoading', 'endLoading']),
    keyDown(event) {
      if (this.isScreenShown && (event.keyCode === 27) && event.ctrlKey) {
        // ESC
        this.isScreenShown = false
      }
      if (this.isScreenShown && (event.keyCode === 13) && event.ctrlKey) {
        // Enter
        // this.showFamily()
      }
    },
    showScreen() {
      this.loadData()
      this.isScreenShown = true
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth', })
      this.firstMember.emailContact = true
    },
    onSave() {
      if (this.family.entity.id === 0) {
        this.forceEntitySave = true
      } else {
        this.forceIndividualSave = true
      }
    },
    onEntitySaved(event) {
      this.forceEntitySave = false
      this.family = event.family
      this.forceIndividualSave = true
    },
    onIndividualSaved(event) {
      this.forceIndividualSave = false
      this.addSuccess('La famille a été créé')
      router.push({ name: 'families-detail', params: { entityId: this.family.entity.id, }, })
      this.isScreenShown = false
      this.family = makeFamily()
      this.firstMember = makeEntityMembership()
    },
    onIndividualFailed() {
      this.forceIndividualSave = false
    },
    async loadData() {
      if (this.isAuthenticated && this.canView && !this.loaded) {
        this.startLoading(this.loadingName)
        try {
          let backendApi, resp

          backendApi = new BackendApi('get', '/api/people/genders/')
          resp = await backendApi.callApi()
          this.setGenders(resp.data.map(elt => makeGender(elt)))

          backendApi = new BackendApi('get', '/api/people/roles/')
          resp = await backendApi.callApi()
          await this.setEntityRoles(resp.data.map(elt => makeEntityRole(elt)))

          if (this.hasPerm('fields.view_fieldsgroup')) {
            backendApi = new BackendApi('get', '/api/fields/groups/')
            resp = await backendApi.callApi()
            await this.setFieldGroups(resp.data.map(elt => makeFieldsGroup(elt)))
          }

          this.loaded = true
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    onUpperCaseChanged(event) {
      this.upperCaseName = event.upperCaseName
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.add-family {
  .add-family-item {
    padding: 10px;
    border: solid 1px #fff;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .add-family-icon {
    top: 220px;
  }

  a.link {
    color: #fff !important;
  }

  a.link-highlight {
    text-decoration: underline;
  }

  a.link.highlight {
    color: #f3d250 !important;
  }

  a.close-link {
    color: #fff !important;
  }

  .add-family-screen-content {
    width: 100vw;
    top: 0;
    left: 0;
    min-height: 100vmax;
    position: absolute;
    background: #454851;
    color: #fff;
    opacity: 1;
    z-index: 50;
    padding: 30px 50px;
  }

  .close-screen-line {
    font-size: 32px;
    margin-bottom: 10px;
  }
}
</style>
