<template>
  <div>
    <div
      :class="included ? '' : 'field-group-block'"
      v-for="fieldsGroup in allowedFieldsGroups"
      :key="fieldsGroup.id"
    >
      <div v-if="!showForm(fieldsGroup)">
        <div class="field-group-header" v-if="!included">
          <b-row>
            <b-col cols="9">
              <b>{{ fieldsGroup.name }}</b>
            </b-col>
            <b-col cols="3" class="text-right">
              <a
                class="btn btn-primary btn-sm"
                href
                @click.prevent="onEdit(false, fieldsGroup)"
                v-if="!readOnly && canChange(fieldsGroup)"
                :class="['edit-fields-group' + fieldsGroup.id, editDisabled ? 'disabled' : '']">
                <i class="fa fa-edit"></i> Modifier
              </a>
            </b-col>
          </b-row>
        </div>
        <div
          v-for="field in fieldsGroup.fields"
          :key="field.id"
          class="field-line"
          :class="{ highlight: isHighlighted(field), }"
        >
          <div><b>{{ field.text }}</b></div>
          <div class="field-text" :class="'field' + field.id">{{ getText(field) | defaultValue('-') }}</div>
          <div class="field-comments" :class="'comments' + field.id" v-if="field.allowComments && getComments(field)">
            {{ getComments(field) }}
          </div>
          <div v-if="field.validityInMonths" class="help-text">Validité {{ field.validityInMonths }} mois</div>
        </div>
      </div>
      <div v-if="showForm(fieldsGroup) && canChange(fieldsGroup)">
        <b-form @submit.prevent="onSave(false)">
          <div class="field-group-header" v-if="!included">
            <b-row>
              <b-col cols="9">
                <b>{{ fieldsGroup.name }}</b>
              </b-col>
            </b-row>
          </div>
          <div v-for="field in fieldsGroup.fields" v-bind:key="field.id" class="field-line">
            <b-form-group
              :id="'field-group-' + field.id"
              :label="checkboxField(field) ? '' : (field.text + (field.isMandatory ? '*' : ''))"
              :label-for="'field-' + field.id"
              :description="field.helpText"
            >
              <b-form-select
                :id="'field' + field.id"
                v-model="fieldsData[field.id]"
                v-if="selectField(field)"
                :required="fieldsData.isMandatory"
                :rel="fieldsData[field.id]">
                <b-form-select-option :value="choice.id" v-for="choice in fieldChoices(field)" :key="choice.id">
                  {{ choice.text }}
                </b-form-select-option>
              </b-form-select>
              <b-form-input
                v-if="textField(field)"
                :id="'field' + field.id"
                v-model="fieldsData[field.id]"
                :required="fieldsData.isMandatory"
                :autocomplete="field.autocomplete ? '' : 'off'"
                type="text"
              ></b-form-input>
              <b-form-input
                v-if="dateField(field)"
                :id="'field' + field.id"
                v-model="fieldsData[field.id]"
                :required="fieldsData.isMandatory"
                type="date"
              ></b-form-input>
              <b-form-input
                v-if="intField(field)"
                :id="'field' + field.id"
                v-model="fieldsData[field.id]"
                :required="fieldsData.isMandatory"
                step="1"
                type="number"
              ></b-form-input>
              <decimal-input
                v-if="decimalField(field)"
                :id="'field' + field.id"
                v-model="fieldsData[field.id]"
                :required="fieldsData.isMandatory"
                allow-null
              ></decimal-input>
              <vue-bootstrap-typeahead
                v-if="typeaheadField(field)"
                :id="'field' + field.id"
                v-model="fieldsData[field.id]"
                :data="getSuggestions(field.id)"
                :ref="'fieldTypeAhead' + field.id"
                :required="fieldsData.isMandatory"
                @hit="onTypeaheadHit(field, $event)"
              />
              <b-form-checkbox
                v-if="checkboxField(field)"
                :id="'field' + field.id"
                v-model="fieldsData[field.id]"
                @change="fieldValueChanged(field)"
                :name="'field' + field.id"
                :value="true"
                :unchecked-value="false"
              >
                {{ field.text }}
              </b-form-checkbox>
              <div v-if="field.regex && !passRegex(field)" class="small-text regex-error">
                La valeur n'est pas valide
              </div>
            </b-form-group>
            <b-form-group
              :id="'comments-group-' + field.id"
              label="Commentaires"
              :label-for="'comments' + field.id"
              v-if="field.allowComments"
            >
              <b-form-input
                :id="'comments' + field.id"
                v-model="commentsData[field.id]"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <b-row class="buttons-bar" v-if="!included">
            <b-col class="text-left">
            </b-col>
            <b-col class="text-right">
              <a class="btn btn-secondary btn-cancel" href @click.prevent="onCancel">Annuler</a>
              <b-button type="submit" variant="primary">Enregistrer</b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <b-modal
      v-if="suggestModalId"
      dialog-class="modal-md"
      :id="suggestModalId"
      @ok="onSuggestSelected"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="OK"
      :ok-disabled="selectedSuggest === null"
    >
      <template v-slot:modal-title>
        <b v-if="suggestField">{{ suggestField.text }}</b>
      </template>
      <b-row>
        <b-col>
          <radio-select
            :choices="suggestForChoices" id="suggest-for-choices" v-model="selectedSuggest"
          ></radio-select>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import RadioSelect from '@/components/Controls/RadioSelect'
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import {
  makeFieldChoice, makeFieldValues, FieldType, APPLY_TO_INDIVIDUALS, APPLY_TO_ADULTS, APPLY_TO_CHILDREN,
  APPLY_TO_ENTITIES, APPLY_TO_FAMILIES, APPLY_TO_ORGANIZATIONS, APPLY_TO_ENTITY_MEMBERS, APPLY_TO_BABIES,
  APPLY_TO_CHILDREN_NOT_BABIES, APPLY_TO_CHILDREN_NOT_BABIES_NOT_TEENAGERS
} from '@/types/fields'
import { makeChoice } from '@/types/base'
import { canChangeGroup, canViewGroup, doesGroupApplyToRole } from '@/utils/fields'

export default {
  name: 'fields-detail',
  components: { DecimalInput, RadioSelect, },
  mixins: [BackendMixin],
  props: {
    individual: Object,
    role: Object,
    entity: Object,
    isFamily: Boolean,
    showIndividual: Boolean,
    showEntity: Boolean,
    readOnly: Boolean,
    included: {
      type: Boolean,
      default: false,
    },
    includedInAddress: {
      type: Boolean,
      default: false,
    },
    forceEdit: {
      type: Boolean,
      default: false,
    },
    forceSave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldsData: {},
      commentsData: {},
      suggestions: {},
      fieldValues: null,
      suggestForChoices: [],
      suggestField: null,
      selectedSuggest: null,
      fieldsGroupEdition: null,
    }
  },
  async mounted() {
    await this.loadValues()
    if (this.forceEdit && this.allowedFieldsGroups.length) {
      this.onEdit(true, this.allowedFieldsGroups[0])
    }
  },
  computed: {
    editMode() {
      return store.getters.editMode
    },
    fieldsGroups() {
      if (store.getters.fieldsGroups) {
        return store.getters.fieldsGroups.filter(elt => {
          if (elt.fields.length === 0) {
            return false
          }
          if (this.included !== elt.included) {
            return false
          }
          if (this.showIndividual) {
            if (this.role.fieldsFilter) {
              // Si un filtre est associé au rôle alors ceci est prioritaire
              return doesGroupApplyToRole(elt.applyTo, this.role)
            }
            if (this.isFamily) {
              if (elt.applyTo.indexOf(APPLY_TO_INDIVIDUALS) >= 0) {
                return true
              }
              if (this.isChild) {
                if (elt.applyTo.indexOf(APPLY_TO_CHILDREN) >= 0) {
                  return true
                }
                if (this.isBaby) {
                  if (elt.applyTo.indexOf(APPLY_TO_BABIES) >= 0) {
                    return true
                  }
                }
                if (this.isNotBaby) {
                  if (elt.applyTo.indexOf(APPLY_TO_CHILDREN_NOT_BABIES) >= 0) {
                    return true
                  }
                }
              } else {
                if (elt.applyTo.indexOf(APPLY_TO_ADULTS) >= 0) {
                  return true
                }
              }
            } else {
              if (elt.applyTo.indexOf(APPLY_TO_ENTITY_MEMBERS) >= 0) {
                return true
              }
            }
          }
          if (this.showEntity) {
            if (elt.includedInAddress !== this.includedInAddress) {
              return false
            }
            if (elt.applyTo.indexOf(APPLY_TO_ENTITIES) >= 0) {
              return true
            }
            if (this.isFamily) {
              if (elt.applyTo.indexOf(APPLY_TO_FAMILIES) >= 0) {
                return true
              }
              if (elt.applyTo.indexOf(APPLY_TO_ORGANIZATIONS) >= 0) {
                return true
              }
            }
          }
          return false
        })
      }
      return []
    },
    isChild() {
      return this.role && this.role.isChild
    },
    isBaby() {
      return this.individual && this.individual.isBaby()
    },
    isNotBaby() {
      return this.individual && this.individual.isNotBaby()
    },
    editDisabled() {
      return (store.getters.editMode !== '')
    },
    allFields() {
      let fields = []
      for (const group of this.fieldsGroups) {
        fields = fields.concat(group.fields)
      }
      return fields
    },
    allowedFieldsGroups() {
      return this.fieldsGroups.filter(fieldsGroup => this.canView(fieldsGroup))
    },
    suggestModalId() {
      if (this.suggestField) {
        return 'bv-modal-select-suggest-for-' + this.suggestField.id
      }
      return ''
    },
  },
  watch: {
    individual: function() {
      if (this.showIndividual && this.individual && this.individual.id) {
        let url = '/api/people/individual-values/' + this.individual.id + '/'
        this.loadValues(url)
      }
    },
    entity: function() {
      if (this.showEntity && this.entity && this.entity.id) {
        let url = '/api/people/entity-values/' + this.entity.id + '/'
        this.loadValues(url)
      }
    },
    forceSave: function() {
      if (this.forceSave) {
        this.onSave(true)
      }
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['setEditMode']),
    showForm(fieldsGroup) {
      return this.fieldsGroupEdition && (this.fieldsGroupEdition.id === fieldsGroup.id)
    },
    fieldChoices(field) {
      const choices = []
      if (!field.isMandatory) {
        choices.push(
          makeFieldChoice({ id: 0, name: '', })
        )
      }
      return choices.concat(field.choices)
    },
    canView(fieldsGroup) {
      return canViewGroup(fieldsGroup.applyTo)
    },
    canChange(fieldsGroup) {
      return canChangeGroup(fieldsGroup.applyTo)
    },
    onEdit(forced, fieldsGroup) {
      if (forced || !this.editDisabled) {
        this.fieldsGroupEdition = fieldsGroup
        this.fieldsData = this.initializeFieldsData(fieldsGroup)
        this.commentsData = this.initializeCommentsData(fieldsGroup)
        for (let field of fieldsGroup.fields) {
          if (field.allowSuggestions) {
            this.loadSuggestions(field)
          }
        }
        if (!forced) {
          if (this.showIndividual) {
            this.setEditMode('fields-' + fieldsGroup.id)
          } else {
            this.setEditMode('entity')
          }
        }
        setTimeout(
          () => {
            for (let field of fieldsGroup.fields) {
              if (field.allowSuggestions) {
                let value = this.fieldsData[field.id]
                let ref = 'fieldTypeAhead' + field.id
                if (value) {
                  this.$refs[ref][0].inputValue = value
                }
              }
            }
          },
          100
        )
      }
    },
    onCancel() {
      this.setEditMode('')
      this.fieldsGroupEdition = null
    },
    getSuggestions(fieldId) {
      return this.suggestions[fieldId] || []
    },
    getText(field) {
      if (this.fieldValues) {
        return this.fieldValues.getText(field)
      }
      return ''
    },
    passRegex(field) {
      if (field.regex) {
        const text = this.fieldsData[field.id] || ''
        if (text && !text.match(field.regex)) {
          return false
        }
      }
      return true
    },
    getValue(field) {
      let value = ''
      let valueFound = false
      if (this.fieldValues) {
        value = this.fieldValues.getValue(field)
        valueFound = (value !== null)
      }
      if (!valueFound) {
        if (field.fieldType === FieldType.Choices) {
          const defaultChoices = (field.choices.filter(elt => elt.isDefault))
          if (defaultChoices.length) {
            value = defaultChoices[0].id
          }
        }
        if (field.fieldType === FieldType.Boolean) {
          if (field.initTrue) {
            value = true
          }
        }
      }
      return value
    },
    isValid(field) {
      if (this.fieldValues) {
        return this.fieldValues.isValid(field)
      }
      return true
    },
    getComments(field) {
      if (this.fieldValues) {
        return this.fieldValues.getComments(field)
      }
      return ''
    },
    initializeFieldsData(fieldsGroupEdition) {
      let data = {}
      for (let field of fieldsGroupEdition.fields) {
        data[field.id] = this.getValue(field)
      }
      return data
    },
    initializeCommentsData(fieldsGroupEdition) {
      let data = {}
      for (let field of fieldsGroupEdition.fields) {
        data[field.id] = this.getComments(field)
      }
      return data
    },
    textField(field) {
      const isText = (field.fieldType === FieldType.Text) || (field.fieldType === FieldType.Encrypted)
      return isText && (!field.allowSuggestions)
    },
    typeaheadField(field) {
      const isText = (field.fieldType === FieldType.Text) || (field.fieldType === FieldType.Encrypted)
      return isText && (field.allowSuggestions)
    },
    selectField(field) {
      return field.fieldType === FieldType.Choices
    },
    checkboxField(field) {
      return field.fieldType === FieldType.Boolean
    },
    dateField(field) {
      return (field.fieldType === FieldType.Date)
    },
    intField(field) {
      return (field.fieldType === FieldType.Integer)
    },
    decimalField(field) {
      return (field.fieldType === FieldType.Decimal)
    },
    getValuesUrl() {
      let url = ''
      if (this.showIndividual && this.individual && this.individual.id) {
        url = '/api/people/individual-values/' + this.individual.id + '/'
      }
      if (this.showEntity && this.entity && this.entity.id) {
        url = '/api/people/entity-values/' + this.entity.id + '/'
      }
      if (this.included) {
        url += '?included=1'
      }
      return url
    },
    onTypeaheadHit(field) {
      const text = this.fieldsData[field.id]
      this.loadSuggestionsFor(field, text)
    },
    onSuggestSelected() {
      this.fieldsData[this.suggestField.id] = this.selectedSuggest.id
      this.fieldsData = { ...this.fieldsData, }
    },
    async loadSuggestionsFor(field, text) {
      if (field.suggestionFor && text) {
        let url = '/api/fields/field-choices-suggestions/' + field.suggestionFor + '/'
        url += '?text=' + text
        const backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          if (resp.data.length === 1) {
            this.fieldsData[field.suggestionFor] = resp.data[0].id
            this.fieldsData = { ...this.fieldsData, }
          } else if (resp.data.length > 1) {
            const matchingFields = this.allFields.filter(elt => elt.id === field.suggestionFor)
            if (matchingFields.length === 1) {
              this.suggestField = matchingFields[0]
              this.suggestForChoices = resp.data.map(elt => makeChoice({ id: elt.id, name: elt.text, }))
              const that = this
              setTimeout(
                function() {
                  that.$bvModal.show(that.suggestModalId)
                }, 200
              )
            }
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async onSave(forced) {
      let fieldsGroup = this.fieldsGroupEdition
      if (fieldsGroup) {
        if (!forced) {
          this.setEditMode('')
        }
        let postData = []
        for (let field of fieldsGroup.fields) {
          let textValue = ''
          const isText = (
            (field.fieldType === FieldType.Text) ||
            (field.fieldType === FieldType.Integer) ||
            (field.fieldType === FieldType.Decimal) ||
            (field.fieldType === FieldType.Date) ||
            (field.fieldType === FieldType.Encrypted)
          )
          if (isText) {
            textValue = '' + (this.fieldsData[field.id] || '')
          }
          postData.push(
            {
              field: field.id,
              text_value: textValue,
              choice_value: (field.fieldType === FieldType.Choices) ? this.fieldsData[field.id] || null : null,
              boolean_value: (field.fieldType === FieldType.Boolean) ? (!!this.fieldsData[field.id]) : null,
              comments: this.commentsData[field.id] || '',
            }
          )
        }
        let url = this.getValuesUrl()
        if (url) {
          const backendApi = new BackendApi('post', url)
          try {
            let resp = await backendApi.callApi(postData)
            this.fieldValues = makeFieldValues(resp.data)
            if (!forced) {
              await this.addSuccess('Les données de "' + fieldsGroup.name + '" ont été enregistrées')
            }
            this.$emit('saved')
            this.fieldsGroupEdition = null
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
        } else {
          await this.addError('Impossible de charger les valeurs des champs')
        }
      } else {
        this.$emit('saved')
        this.fieldsGroupEdition = null
      }
    },
    async loadValues() {
      let url = this.getValuesUrl()
      if (url) {
        const backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.fieldValues = makeFieldValues(resp.data)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      } else {
        await this.addError('Impossible de charger les valeurs des champs')
      }
    },
    fieldValueChanged(field) {
    },
    async loadSuggestions(field) {
      let url = ''
      if (this.showIndividual && this.individual) {
        url = '/api/people/individual-values-suggestions/' + field.id + '/'
      }
      if (this.showEntity && this.entity) {
        url = '/api/people/entity-values-suggestions/' + field.id + '/'
      }
      if (url) {
        const backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.suggestions[field.id] = resp.data
          this.suggestions = { ...this.suggestions, }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    isHighlighted(field) {
      return (
        (field.highlightIfTrue && this.getValue(field)) ||
        (!this.isValid(field))
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.field-comments {
  background: #ccc;
  margin-top: 2px;
  padding: 5px;
}
.highlight {
  background: #ffca22;
  color: #222;
}
.regex-error {
  color: #cc3700;
}
</style>
