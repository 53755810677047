<template>
  <div class="send-email-modal">
    <b-modal
      :id="'bv-send-email-modal:' + name"
      cancel-title="Non"
      @ok="onConfirm"
      @cancel="onCancel"
      :ok-disabled="!isValid"
      ok-variant="primary"
      ok-title="Envoyer"
      size="xl"
    >
      <template v-slot:modal-title>
        {{ title }}
      </template>
      <b-form>
        <div v-if="text" v-html="safeText" class="intro-text">
        </div>
        <b-row>
          <b-col cols="3">
            <b-row>
              <b-col>
                <b-form-group
                  id="fromEmail-group"
                  label="Répondre à"
                  label-for="fromEmail"
                  description="Adresse pour la réponse des familles aux emails envoyés"
                >
                  <b-form-input
                    id="fromEmail"
                    type="email"
                    required
                    v-model="fromEmail"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="choices">
              <b-col>
                <check-box-select
                  :choices="choices"
                  :initial-value="choices"
                  @changed="onChoicesChanged"
                  @init="onChoicesChanged"
                >
                </check-box-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col>
                <b-form-group
                  id="subject-group"
                  label="Sujet"
                  label-for="subject"
                  description="Sujet de l'email"
                >
                  <b-form-input
                    id="subject"
                    type="text"
                    required
                    v-model="subject"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
              id="body-group"
              label="Message"
              label-for="body"
              :description="bodyRequired ? 'Message obligatoire' : 'Ce message optionnel sera inclus dans chaque email envoyé'"
            >
              <b-form-textarea
                id="body"
                v-model="body"
                rows="15"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import store from '@/store'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect.vue'
import { htmlText } from '@/utils/html'

export default {
  name: 'SendEmailModal',
  components: {
    CheckBoxSelect,
  },
  props: {
    name: String,
    title: String,
    initSubject: {
      type: String,
      defaultValue: 'Confirmation',
    },
    initBody: {
      type: String,
      defaultValue: '',
    },
    initFromEmail: {
      type: String,
      defaultValue: '',
    },
    text: {
      type: String,
      defaultValue: '',
    },
    bodyRequired: {
      type: Boolean,
      default: false,
    },
    choices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fromEmail: '',
      subject: '',
      body: '',
      selectedChoices: [],
    }
  },
  watch: {
    initSubject: function() {
      this.subject = this.initSubject
    },
    initBody: function() {
      this.body = this.initBody
    },
    initFromEmail: function() {
      this.fromEmail = this.initFromEmail || this.userEmail
    },
    userEmail: function() {
      this.fromEmail = this.initFromEmail || this.userEmail
    },
  },
  created() {
    this.fromEmail = this.initFromEmail || this.userEmail
    this.subject = this.initSubject
  },
  computed: {
    isValid() {
      const body = this.body.replace(' ', '').replace('\n', '')
      if (this.bodyRequired && body.length === 0) {
        return false
      }
      if ((this.choices.length > 0) && (this.selectedChoices.length === 0)) {
        return false
      }
      return this.subject && this.fromEmail
    },
    userEmail() {
      return store.getters.userEmail
    },
    safeText() {
      return htmlText(this.text)
    },
  },
  methods: {
    onConfirm() {
      if (this.isValid) {
        this.$emit(
          'confirmed',
          {
            subject: this.subject,
            body: this.body,
            fromEmail: this.fromEmail,
            choices: this.selectedChoices,
          }
        )
        this.subject = ''
        this.body = ''
      }
    },
    onCancel() {
      this.$emit('cancelled')
    },
    onChoicesChanged(event) {
      this.selectedChoices = event.choices
      this.$emit('choices-changed', event)
    },
  },
}
</script>
<style lang="less" scoped>
.intro-text {
  padding: 10px;
  margin-bottom: 5px;
  background: #f0f0f0;
  color: #222;
  font-size: 16px;
}
.dark-mode {
  .intro-text {
    background: #444;
    color: #fff;
  }
}
</style>
