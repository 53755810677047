import { UserPreferences } from '@/types/users'
import { decrypt, encrypt } from '@/utils/encrypt'

export function storePreferences(preferences: UserPreferences): void {
  const preferencesAsString: string = JSON.stringify(preferences)
  window.localStorage.setItem('preferences', encrypt(preferencesAsString))
}

export function loadPreferences(): UserPreferences {
  const encryptedValue = window.localStorage.getItem('preferences')
  if (encryptedValue) {
    let value = ''
    try {
      value = decrypt(encryptedValue)
    } catch (exc1) {
      value = ''
    }
    if (value) {
      try {
        const preferences: any = JSON.parse(value)
        if (preferences) {
          return new UserPreferences(
            preferences.id,
            preferences.activeSchoolYears,
            preferences.autoLogout,
            preferences.logoutOnClose(),
            preferences.darkMode,
            preferences.pdfMargin
          )
        }
      } catch (exc2) {
      }
    }
  }
  return new UserPreferences(
    0,
    [],
    15,
    false,
    false,
    null
  )
}
