import { makeSchoolYear, SchoolYear } from '@/types/schools'

export class UsersGroup {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeUsersGroups(jsonData: any = null): UsersGroup {
  if (!jsonData) {
    jsonData = {}
  }
  return new UsersGroup(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class StaffUser {
  constructor(
    public id: number,
    public lastName: string,
    public firstName: string,
    public email: string,
    public isActive: boolean,
    public isSuperUser: boolean,
    public lastLogin: Date,
    public groups: UsersGroup[]
  ) {
  }

  public fullName(): string {
    let name = this.firstName + ' ' + this.lastName
    name = name.trim()
    if (!name) {
      name = this.email
    }
    return name
  }
}

function sortedGroups(groups: UsersGroup[]): UsersGroup[] {
  return groups.sort(
    function(elt1, elt2) {
      if (elt1.name === 'Gestion totale') {
        return -1
      } else if (elt2.name === 'Gestion totale') {
        return 1
      } else if (elt1.name < elt2.name) {
        return -1
      } else if (elt1.name > elt2.name) {
        return 1
      } else {
        return 0
      }
    }
  )
}

export function makeStaffUser(jsonData: any = null): StaffUser {
  if (!jsonData) {
    jsonData = {}
  }
  let groups = jsonData.groups || []
  return new StaffUser(
    jsonData.id || 0,
    jsonData.last_name || '',
    jsonData.first_name || '',
    jsonData.email || '',
    !!jsonData.is_active,
    !!jsonData.is_superuser,
    jsonData.last_login || null,
    sortedGroups(groups.map((elt: any) => makeUsersGroups(elt)))
  )
}

export class UserPreferences {
  constructor(
    public id: number,
    public activeSchoolYears: SchoolYear[],
    public autoLogout: number,
    public logoutOnClose: boolean,
    public darkMode: boolean,
    public pdfMargin: number|null
  ) {
  }
}

export function makeUserPreferences(jsonData: any = null): UserPreferences {
  if (!jsonData) {
    jsonData = {}
  }
  let activeSchoolYears: SchoolYear[] = []
  if (jsonData.active_school_years) {
    activeSchoolYears = jsonData.active_school_years.map((elt: any) => makeSchoolYear({ id: elt, }))
  }
  return new UserPreferences(
    jsonData.id || 0,
    activeSchoolYears,
    jsonData.auto_logout || 0, // nombre de minutes avant auto_logout,
    !!jsonData.logout_on_close, // déconnexion lorsqu'on quitte le navigateur
    !!jsonData.dark_mode,
    (jsonData.pdf_margin !== null) ? +jsonData.pdf_margin : null
  )
}
