/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from 'moment'
import { dateToString } from '@/filters/texts'
import { Choice, makeChoice } from '@/types/base'
import { Child } from '@/types/families'
import { makeReward, makeSaleWithInvoice, Reward, SaleWithInvoice } from '@/types/payments'
import { Entity, Individual, makeEntity, makeIndividual } from '@/types/people'
import { makeSchoolYear, SchoolYear } from '@/types/schools'
import { makeSeanceWelfare, SeanceTariff, SeanceWelfare } from '@/types/tariffs'
import { isNumber } from '@/utils/check'
import { sum } from '@/utils/math'
import { calcHeaderStyle } from '@/utils/style'
import { existsIn } from '@/utils/arrays'
import { ActivityCategory } from '@/types/activities'

export enum GroupByChoice {
  AgeGroup = 1,
  SchoolAndClass = 2,
  SchoolLevel = 3,
  AllInOneGroup = 4,
  School = 5,
  AgeGroupGlobal = 6 // groupe d'âge en prenant l'âge de l'enfant au jour de la séance
}

export function groupByChoices(
  includeSchoolLevels: boolean,
  includeSeanceGroups: Seance[] = [],
  includeDays: String[] = [],
  onlyGlobal = false
): Choice[] {
  const choices = [
    makeChoice({ id: GroupByChoice.AgeGroup, name: 'Par groupe d\'age', })
  ]
  if (onlyGlobal) {
    choices.push(
      makeChoice({ id: GroupByChoice.AgeGroupGlobal, name: 'Par groupe d\'age (seulement globaux)', })
    )
  }
  for (const day of includeDays) {
    const intVal = +(day.replace('-', ''))
    choices.push(
      makeChoice({
        id: intVal,
        name: 'Pour la journée du "' + dateToString(day, 'DD/MM/YYYY') + '"',
        extra: day,
      })
    )
  }
  for (const seance of includeSeanceGroups) {
    choices.push(
      makeChoice({
        id: -seance.id, name: 'Par groupe "' + seance.getShortName() + '"', extra: seance.id,
      })
    )
  }
  choices.push(
    makeChoice({ id: GroupByChoice.SchoolAndClass, name: 'Par école et par classe', }),
    makeChoice({ id: GroupByChoice.School, name: 'Par école', })
  )
  if (includeSchoolLevels) {
    choices.push(
      makeChoice({ id: +GroupByChoice.SchoolLevel, name: 'Par niveau scolaire', })
    )
  }
  choices.push(makeChoice({ id: +GroupByChoice.AllInOneGroup, name: 'Tous les inscrits', }))
  return choices
}

export class SeanceAnalyticAccount {
  constructor(
    public id: number,
    public name: string,
    public code: string
  ) {
  }
}

export function makeSeanceAnalyticAccount(jsonData: any = null): SeanceAnalyticAccount {
  if (!jsonData) {
    jsonData = {}
  }
  return new SeanceAnalyticAccount(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.code || ''
  )
}

export enum YouthHomeAgeRule {
  SeanceDate = 1,
  CivilYear = 2,
  SchoolLevel = 3
}

export function getPeriodHeaderStyle(elt: any): any {
  return calcHeaderStyle([elt.period, elt.seanceType, elt.youthHome, elt.schoolYear])
}

export class YouthHome {
  constructor(
    public id: number,
    public number: number,
    public name: string,
    public schoolYear: SchoolYear,
    public activity: number,
    public scale: number,
    public ageRule: YouthHomeAgeRule,
    public ageRuleName: string,
    public overrideShowByDays: boolean,
    public showByDays: boolean,
    public showByDaysPortal: boolean,
    public canCloneSeancesOn: number[],
    public showMomentSelector: boolean,
    public allowSwitchInscription: boolean,
    public showBySeance: boolean,
    public allowWaitingList: boolean,
    public backgroundColor: string,
    public textColor: string,
    public clockingColumns: string[],
    public overrideClockingColumns: boolean,
    public technicalCategory: ActivityCategory,
    public allowInvoicing: boolean,
    public dayTimeLabels: string[],
    public dayTimeLetters: string[],
    public payOnDaily: boolean,
    public allowMultiplier: boolean,
    public showUnpaidInvoicesOnConfirm: boolean,
    public hideHandicapOnList: boolean,
    public showAllMembersOnDailyList: boolean
  ) {
  }

  public baseName() {
    return this.name.replace(' - ' + this.schoolYear.name, '')
  }

  public getHeaderStyle() {
    return calcHeaderStyle([this, this.schoolYear])
  }

  public allowAdults(): boolean {
    return this.technicalCategory === ActivityCategory.AdultsYouthHome
  }
}

export enum WorkshopMoments {
  None = 0,
  Morning = 1,
  Lunch = 2,
  Afternoon = 3,
  Evening = 4,
}

export enum DayTimeValues {
  None = 0,
  Morning = 1,
  Lunch = 2,
  Afternoon = 3,
  Evening = 4,
  Picnic = 5
}

export enum PaidFilter {
    All = 0,
    Paid = 1,
    Unpaid = 2,
}

export enum KindFilter {
  All = 0,
  Inscriptions = 1,
  Cancellations = 2,
}

export enum StatusFilter {
  ToDo = 0, // à traiter
  Refused = 1, // refusées
  Waiting = 2, // liste d'attente
  Refund = 3, // à rembourser : liste d'attente pour les séances passées
}

export class DayTime {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function getDayTimes(labels: string[] = []): DayTime[] {
  return [
    new DayTime(DayTimeValues.Morning, labels.length > 0 ? labels[0] : 'Matin'),
    new DayTime(DayTimeValues.Lunch, labels.length > 1 ? labels[1] : 'Repas'),
    new DayTime(DayTimeValues.Afternoon, labels.length > 2 ? labels[2] : 'Après-midi'),
    new DayTime(DayTimeValues.Evening, labels.length > 3 ? labels[3] : 'Soirée'),
    new DayTime(DayTimeValues.Picnic, labels.length > 4 ? labels[4] : 'Pique-Nique')
  ]
}

export enum DayMoments {
    None = 0,
    Opening,
    Closing,
    Opening2,
    Closing2,
    Opening3,
    Closing3,
}

export enum CitySpecific {
  No = 0, // Seulement Hors-Commune
  Yes = 1, // Seulement Commune
  NA = 2, // Non applicable
}

export function citySpecificDisplay(citySpecific: CitySpecific): string {
  if (citySpecific === CitySpecific.No) {
    return 'Seulement Hors-Commune'
  }
  if (citySpecific === CitySpecific.Yes) {
    return 'Seulement Commune'
  }
  if (citySpecific === CitySpecific.NA) {
    return 'Indifférent'
  }
  return '?'
}

export function makeYouthHome(jsonData: any = null, schoolYearData: any = null): YouthHome {
  if (!jsonData) {
    jsonData = {}
  }
  return new YouthHome(
    jsonData.id || 0,
    jsonData.number || 0,
    jsonData.name || '',
    (schoolYearData === null) ? makeSchoolYear(jsonData.school_year) : makeSchoolYear(schoolYearData),
    jsonData.activity || 0,
    jsonData.scale || 0,
    jsonData.age_rule || YouthHomeAgeRule.SeanceDate,
    jsonData.age_rule_name || '',
    !!jsonData.override_show_by_days,
    !!jsonData.show_by_days,
    !!jsonData.show_by_days_portal,
    jsonData.can_clone_seances_on ? jsonData.can_clone_seances_on : [],
    !!jsonData.show_moment_selector,
    !!jsonData.allow_switch_inscription,
    !!jsonData.show_by_seance,
    !!jsonData.allow_waiting_list,
    jsonData.background_color || '',
    jsonData.text_color || '',
    jsonData.clocking_columns || [],
    !!jsonData.override_clocking_columns,
    jsonData.technical_category || ActivityCategory.Event,
    !!jsonData.allow_invoicing,
    jsonData.daytime_labels || [],
    jsonData.daytime_letters || [],
    !!jsonData.pay_on_daily,
    jsonData.allow_multiplier,
    !!jsonData.show_unpaid_invoices_on_confirm,
    !!jsonData.hide_handicap_on_list,
    !!jsonData.show_all_members_on_daily_list
  )
}

export class OpeningHours {
  constructor(
    public openingAt: string,
    public closingAt: string,
    public openingAt2: string,
    public closingAt2: string,
    public openingAt3: string,
    public closingAt3: string,
    public lunchStartsAt: string,
    public lunchEndsAt: string,
    public maxArrivalAt: string,
    public minDepartureAt: string,
    public maxArrivalAt2: string,
    public minDepartureAt2: string,
    public step: number,
    public directLimit: number,
    public directCeil: number,
    public commonArrivalAndDeparture: boolean,
    public fixedLunch: boolean,
    public period: SeancePeriod|null = null,
    public date: string = ''
  ) {
  }
}

export function makeOpeningHours(jsonData: any = null): OpeningHours {
  if (!jsonData) {
    jsonData = {}
  }
  return new OpeningHours(
    jsonData.opening_at || '',
    jsonData.closing_at || '',
    jsonData.opening_at2 || '',
    jsonData.closing_at2 || '',
    jsonData.opening_at3 || '',
    jsonData.closing_at3 || '',
    jsonData.lunch_starts_at || '',
    jsonData.lunch_ends_at || '',
    jsonData.morning_max_arrival || '',
    jsonData.morning_min_departure || '',
    jsonData.afternoon_max_arrival || '',
    jsonData.afternoon_min_departure || '',
    jsonData.step || 30,
    jsonData.direct_limit || 0,
    jsonData.direct_ceil || 0,
    !!jsonData.common_arrival_and_departure,
    !!jsonData.fixed_lunch,
    jsonData.period ? makeSeancePeriod(jsonData.period) : null,
    jsonData.date || ''
  )
}

export class SeancePeriodTimeframe {
  constructor(
    public id: number,
    public schoolYearId: number,
    public dateFrom: Date,
    public dateTo: Date
  ) {
  }

  public clone(): SeancePeriodTimeframe {
    return new SeancePeriodTimeframe(
      this.id, this.schoolYearId, this.dateFrom, this.dateTo
    )
  }
}

export function makeSeancePeriodTimeframe(jsonData: any = null): SeancePeriodTimeframe {
  if (!jsonData) {
    jsonData = {}
  }
  return new SeancePeriodTimeframe(
    jsonData.id || 0,
    jsonData.school_year || 0,
    jsonData.date_from,
    jsonData.date_to
  )
}

export class SeancePeriod {
  constructor(
    public id: number,
    public name: string,
    public youthHomes: number[],
    public seanceTypes: string[],
    public timeframes: SeancePeriodTimeframe[],
    public backgroundColor: string,
    public textColor: string
  ) {
  }

  public getTimeframe(schoolYearId: number): Date[] {
    for (const timeframe of this.timeframes) {
      if (timeframe.schoolYearId === schoolYearId) {
        return [timeframe.dateFrom, timeframe.dateTo]
      }
    }
    return []
  }
}

export function makeSeancePeriod(jsonData: any = null): SeancePeriod {
  if (!jsonData) {
    jsonData = {}
  }
  const timeframes = (jsonData.timeframes || [])
  return new SeancePeriod(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.youth_homes || [],
    jsonData.seance_types || [],
    timeframes.map((elt: any) => makeSeancePeriodTimeframe(elt)),
    jsonData.background_color || '',
    jsonData.text_color || ''
  )
}

export enum SeanceTypeDays {
    ALL_DAYS = 0,
    WEEK_DAYS = 1,
    WEEK_DAYS_NO_WEDNESDAY = 2,
    WEDNESDAY = 3,
    SATURDAY = 4
}

export enum MultiInscriptionsModalChoices {
  STANDARD = 0,
  QUICK = 1
}

export enum TariffType {
  SEANCE_TARIFF = 0,
  HOURLY_TARIFF = 1,
  CLOCK_TARIFF = 2
}

export function getTariffTypeLabel(tariffType: TariffType): string {
  switch (tariffType) {
    case TariffType.SEANCE_TARIFF:
      return 'Tarif de la séance'
    case TariffType.HOURLY_TARIFF:
      return 'Tarif horaire sans pointage'
    case TariffType.CLOCK_TARIFF:
      return 'Tarif horaire avec pointage'
  }
  return 'Type de tarif invalide'
}

export class SeanceType {
  constructor(
    public id: number,
    public name: string,
    public tariffType: TariffType,
    public showByDays: boolean,
    public showByDaysPortal: boolean,
    public days: SeanceTypeDays,
    public weeksNumber: number,
    public youthHomes: number[],
    public backgroundColor: string,
    public textColor: string,
    public clockingColumns: string[],
    public clockingSeance: boolean,
    public multiInscriptionModal: MultiInscriptionsModalChoices,
    public showDurationOnListing: boolean,
    public schoolDay: boolean
  ) {
  }

  public getDays(): boolean[] {
    switch (this.days) {
      case SeanceTypeDays.WEEK_DAYS:
        return [true, true, true, true, true, false, false]
      case SeanceTypeDays.WEEK_DAYS_NO_WEDNESDAY:
        return [true, true, false, true, true, false, false]
      case SeanceTypeDays.WEDNESDAY:
        return [false, false, true, false, false, false, false]
      case SeanceTypeDays.SATURDAY:
        return [false, false, false, false, false, true, false]
      default:
        return [true, true, true, true, true, true, true]
    }
  }

  public isWeeklyListVisible(): boolean {
    switch (this.days) {
      case SeanceTypeDays.WEEK_DAYS:
        return true
      case SeanceTypeDays.WEEK_DAYS_NO_WEDNESDAY:
        return true
      default:
        return false
    }
  }

  public wednesdayInWeek(): boolean {
    switch (this.days) {
      case SeanceTypeDays.WEEK_DAYS:
        return true
      case SeanceTypeDays.WEEK_DAYS_NO_WEDNESDAY:
        return false
      default:
        return false
    }
  }

  public isSeveralWeeksVisible(): boolean {
    switch (this.days) {
      case SeanceTypeDays.WEDNESDAY:
        return true
      case SeanceTypeDays.SATURDAY:
        return true
      default:
        return false
    }
  }

  public getTariffTypeLabel(): string {
    return getTariffTypeLabel(this.tariffType)
  }

  public isHourlyTariff(): boolean {
    return existsIn([this.tariffType], [TariffType.HOURLY_TARIFF, TariffType.CLOCK_TARIFF])
  }
}

export function makeSeanceType(jsonData: any = null): SeanceType {
  if (!jsonData) {
    jsonData = {}
  }
  return new SeanceType(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.tariff_type,
    !!jsonData.show_by_days,
    !!jsonData.show_by_days_portal,
    jsonData.days || SeanceTypeDays.ALL_DAYS,
    jsonData.weeks_number || 0,
    jsonData.youth_homes || [],
    jsonData.background_color || '',
    jsonData.text_color || '',
    jsonData.clocking_columns || [],
    !!jsonData.clocking_seance,
    jsonData.multi_inscription_modal || MultiInscriptionsModalChoices.STANDARD,
    !!jsonData.show_duration_on_listing,
    !!jsonData.school_day
  )
}

export class SeanceSynthesisElt {
  constructor(
    public index: number,
    public period: SeancePeriod,
    public seanceType: SeanceType,
    public youthHome: YouthHome,
    public schoolYear: SchoolYear,
    public inscriptionsCount: number,
    public allInscriptionsCount: number
  ) {
  }
}

export function makeSeanceSynthesisElt(jsonData: any = null, index: number = 0): SeanceSynthesisElt {
  if (!jsonData) {
    jsonData = {}
  }
  return new SeanceSynthesisElt(
    index,
    makeSeancePeriod(jsonData.period),
    makeSeanceType(jsonData.seance_type),
    makeYouthHome(jsonData.youth_home, jsonData.school_year),
    makeSchoolYear(jsonData.school_year),
    jsonData.inscriptions_count || 0,
    jsonData.all_inscriptions_count || 0
  )
}

export class Workshop {
  constructor(
    public id: number,
    public name: string,
    public price: number,
    public moment: WorkshopMoments,
    public maxNumber: number,
    public discountable: boolean,
    public inscriptionsCount: number
  ) {
  }

  public getMomentName(): string {
    switch (this.moment) {
      case WorkshopMoments.Morning:
        return 'Matin'
      case WorkshopMoments.Lunch:
        return 'Repas'
      case WorkshopMoments.Afternoon:
        return 'Après-Midi'
      case WorkshopMoments.Evening:
        return 'Soir'
    }
    return ''
  }
}

export function makeWorkshop(jsonData: any = null): Workshop {
  if (!jsonData) {
    jsonData = {}
  }
  return new Workshop(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.price ? +jsonData.price : 0,
    jsonData.moment || WorkshopMoments.None,
    jsonData.max_number ? +jsonData.max_number : 0,
    !!jsonData.discountable,
    jsonData.inscriptions_count || 0
  )
}

export class WorkshopInscription {
  constructor(
    public workshop: number,
    public moment: WorkshopMoments
  ) {
  }
}

export function makeWorkshopInscription(jsonData: any = null): WorkshopInscription {
  if (!jsonData) {
    jsonData = {}
  }
  return new WorkshopInscription(
    jsonData.workshop || 0,
    jsonData.moment
  )
}

export enum Excursion {
  None = 0,
  Trip = 1, // Sortie
  Stay = 2 // Séjour
}

export enum SeanceLock {
  None = 0,
  Inscriptions = 1,
  Total = 2
}

export function getExcursionChoices(): Choice[] {
  return [
    makeChoice({ id: Excursion.None, name: 'Standard', }),
    makeChoice({ id: Excursion.Trip, name: 'Sortie', }),
    makeChoice({ id: Excursion.Stay, name: 'Séjour', })
  ]
}

export class BaseSeance {
  constructor(
    public id: number,
    public name: string,
    public baseName: string,
    public code: string,
    public period: SeancePeriod,
    public seanceType: SeanceType,
    public youthHome: YouthHome,
    public date: Date,
    public morning: boolean,
    public lunch: boolean,
    public picnic: boolean,
    public afternoon: boolean,
    public evening: boolean,
    public comments: boolean,
    public duration: number,
    public parent: number, // si inscription pour la séance parent alors
    public children: number[], // on s'inscrit aussi automatiquement pour les enfants
    public workshops: Workshop[],
    public order: number,
    public alias: string,
    public fixedFee: boolean,
    public fixedFeeAuto: boolean,
    public isReward: boolean, // chantiers
    public excursion: Excursion,
    public preferenceGroup: string,
    public lock: SeanceLock,
    public forceTariffType: boolean,
    public customTariffType: TariffType
  ) {
  }

  public getLongCode(date: Date, codeName: string, youthHomeNumber: number): string {
    // construit le code complet de la séance
    const dateValue = dateToString(date, 'DD/MM/YYYY')
    return codeName + dateValue + ' - ' + youthHomeNumber
  }

  public getBaseName(): string {
    if (this.baseName) {
      return this.baseName
    }
    return this.getShortName()
  }

  public getShortName(): string {
    // nom de la séance sans la date
    const dateName = dateToString(this.date, 'dddd LL')
    return this.name.replace(dateName, '').trim()
  }

  public getDateName(): string {
    // date de la séance
    return dateToString(this.date, 'dddd LL')
  }

  public getShortDateName(): string {
    // date de la séance
    return dateToString(this.date, 'ddd ll')
  }

  public getLongName(date: Date, shortName: string): string {
    // reconstruit le nom complet de la séance
    const dateName = dateToString(date, 'dddd LL')
    return dateName + ' ' + shortName
  }

  public getCodeName(): string {
    // code d'une séance
    const regex = /(.+?)(\d{2}\/\d{2}\/\d{2,4}.*)/ig
    const matches = regex.exec(this.code)
    if (matches) {
      return matches[1]
    }
    return ''
  }

  public isExcursion(): boolean {
    return (this.excursion !== Excursion.None)
  }

  public getCodeOrAlias(): string {
    // nom de la séance sans la date
    if (this.alias) {
      return this.alias
    } else {
      return this.getCodeName()
    }
  }

  public hasNoMoment(): boolean {
    return !this.morning && !this.lunch && !this.afternoon && !this.evening
  }

  public sortNumber(): number {
    if (this.order) {
      return this.order
    }

    let value = 0
    if (this.morning && !this.afternoon) {
      // Matin
      value = this.lunch ? 2 : 1
    } else if (!this.morning && this.afternoon) {
      // Après-midi
      value = this.lunch ? 6 : 5
    } else if (this.morning && this.afternoon) {
      // Journée
      if (!this.evening) {
        value = this.lunch ? 4 : 3
      } else {
        value = this.lunch ? 8 : 7
      }
    } else if (this.evening) {
      // Soirée
      value = 9
    } else {
      value = 10
    }
    if (this.fixedFee) {
      value = value - 20
    }
    return value
  }
}

export class ForbiddenInscription {
  constructor(
    public individualId: number,
    public reason: string
  ) {
  }

  public clone(): ForbiddenInscription {
    return new ForbiddenInscription(this.individualId, this.reason)
  }
}

export function makeForbiddenInscription(jsonData: any = null): ForbiddenInscription {
  if (!jsonData) {
    jsonData = {}
  }
  return new ForbiddenInscription(
    jsonData.individual || 0,
    jsonData.reason || ''
  )
}

export class EntitySeance extends BaseSeance {
  constructor(
    public id: number,
    public name: string,
    public baseName: string,
    public code: string,
    public period: SeancePeriod,
    public seanceType: SeanceType,
    public youthHome: YouthHome,
    public date: Date,
    public morning: boolean,
    public lunch: boolean,
    public picnic: boolean,
    public afternoon: boolean,
    public evening: boolean,
    public comments: boolean,
    public duration: number,
    public parent: number, // si inscription pour la séance parent alors
    public children: number[], // on s'inscrit aussi automatiquement pour les enfants
    public workshops: Workshop[],
    public order: number,
    public alias: string,
    public fixedFee: boolean,
    public fixedFeeAuto: boolean,
    public isReward: boolean,
    public excursion: Excursion,
    public preferenceGroup: string,
    public lock: SeanceLock,
    public forceTariffType: boolean,
    public customTariffType: TariffType,
    public inscriptions: any = {},
    public availableForIndividualIds: number[],
    public blockedForIndividualIds: number[],
    public inscriptionForIndividualIds: number[],
    public newInscriptionForIndividualIds: number[],
    public cancelledInscriptionForIndividualIds: number[],
    public workshopInscriptions: any = {},
    public originalWorkshopInscriptions: any = {},
    public forbiddenInscriptions: ForbiddenInscription[],
    public othersInscriptions: number[],
    public dependencies: number[],
    public allowDiscountOnFreePrice: boolean,
    public multipliers: Map<number, number> = new Map(),
    public newMultipliers: Map<number, number> = new Map(),
    public dayId: number = 0
  ) {
    super(
      id, name, baseName, code, period, seanceType, youthHome, date, morning, lunch, picnic, afternoon, evening, comments,
      duration, parent, children, workshops, order, alias, fixedFee, fixedFeeAuto, isReward, excursion,
      preferenceGroup, lock, forceTariffType, customTariffType
    )
    this.dayId = moment(date).weekday() + 1
  }

  public clone(): EntitySeance {
    return new EntitySeance(
      this.id, this.name, this.baseName, this.code, this.period, this.seanceType, this.youthHome, this.date,
      this.morning, this.lunch, this.picnic, this.afternoon, this.evening, this.comments,
      this.duration, this.parent, this.children, this.workshops, this.order, this.alias, this.fixedFee,
      this.fixedFeeAuto, this.isReward, this.excursion, this.preferenceGroup, this.lock, this.forceTariffType,
      this.customTariffType, { ...this.inscriptions, },
      this.availableForIndividualIds.concat([]), this.blockedForIndividualIds.concat([]),
      this.inscriptionForIndividualIds.concat([]), this.newInscriptionForIndividualIds.concat([]),
      this.cancelledInscriptionForIndividualIds.concat([]), { ...this.workshopInscriptions, },
      { ...this.originalWorkshopInscriptions, },
      this.forbiddenInscriptions.map(elt => elt.clone()),
      this.othersInscriptions.concat([]), this.dependencies.concat([], this.dependencies),
      this.allowDiscountOnFreePrice, new Map(this.multipliers), new Map(this.newMultipliers), this.dayId
    )
  }

  public hasWorkshopInscriptionChanged(individualId: number): boolean {
    let initialWorkshops: WorkshopInscription[] = []
    let currentWorkshops: WorkshopInscription[] = []
    if (individualId in this.workshopInscriptions) {
      currentWorkshops = this.workshopInscriptions[individualId]
    }

    if (individualId in this.originalWorkshopInscriptions) {
      initialWorkshops = this.originalWorkshopInscriptions[individualId]
    }

    currentWorkshops = currentWorkshops.filter((elt: WorkshopInscription) => elt.workshop > 0)
    initialWorkshops = initialWorkshops.filter((elt: WorkshopInscription) => elt.workshop > 0)
    if ((currentWorkshops.length !== initialWorkshops.length)) {
      return true
    } else {
      for (let index = 0; index < currentWorkshops.length; index++) {
        const currentWorkshop = currentWorkshops[index]
        const initialWorkshop = initialWorkshops[index]
        if (
          (currentWorkshop.workshop !== initialWorkshop.workshop) ||
          (currentWorkshop.moment !== initialWorkshop.moment)
        ) {
          return true
        }
      }
      return false
    }
  }

  public hasWorkshopChanges(): boolean {
    if (this.workshops.length > 0) {
      for (const individualId of this.availableForIndividualIds) {
        if (this.hasWorkshopInscriptionChanged(individualId)) {
          return true
        }
      }
    }
    return false
  }

  public hasInscriptions(): boolean {
    // est-ce qu'un individu est inscrit a cette séance
    return (
      (
        (this.inscriptionForIndividualIds.length > 0) &&
        (this.cancelledInscriptionForIndividualIds.length < this.inscriptionForIndividualIds.length)
      ) || (
        this.newInscriptionForIndividualIds.length > 0
      )
    )
  }

  public doesIndividualHaveInscription(individualId: number): boolean {
    // est-ce que l'individu est inscrit a cette séance
    return (
      (
        (this.inscriptionForIndividualIds.indexOf(individualId) >= 0) &&
        (this.cancelledInscriptionForIndividualIds.indexOf(individualId) < 0)
      ) || (
        this.newInscriptionForIndividualIds.indexOf(individualId) >= 0
      )
    )
  }

  public getIndividualMultiplier(individualId: number): number {
    // Combien d'inscriptions pour cette séance?
    // Pour les cantines, une même personne peut réserver plusieurs repas
    if (this.doesIndividualHaveInscription(individualId)) {
      if (this.youthHome.allowMultiplier) {
        if (this.multipliers.has(individualId)) {
          const value = this.multipliers.get(individualId)
          if (value !== undefined) {
            return value
          }
        }
      }
      return 1
    }
    return 0
  }

  public getIndividualNewMultiplier(individualId: number): number {
    // Combien d'inscriptions pour cette séance?
    // Pour les cantines, une même personne peut réserver plusieurs repas
    if (this.doesIndividualHaveInscription(individualId)) {
      if (this.youthHome.allowMultiplier) {
        if (this.newMultipliers.has(individualId)) {
          const value = this.newMultipliers.get(individualId)
          if (value !== undefined) {
            return value
          }
        }
        if (this.multipliers.has(individualId)) {
          const value = this.multipliers.get(individualId)
          if (value !== undefined) {
            return value
          }
        }
      }
      return 1
    }
    return 0
  }

  public getIndividualInitialMultiplier(individualId: number): number {
    if (this.multipliers.has(individualId)) {
      const value = this.multipliers.get(individualId)
      if (value !== undefined) {
        return value
      }
    }
    return 0
  }

  public getIndividualMultiplierDelta(individualId: number): number {
    // Combien d'inscriptions pour cette séance?
    // Pour les cantines, une même personne peut réserver plusieurs repas
    if (this.doesIndividualHaveInscription(individualId)) {
      if (this.youthHome.allowMultiplier) {
        let newValue = 0
        let prevValue = 0
        let valueNotSet = true
        if (this.newMultipliers.has(individualId)) {
          const value = this.newMultipliers.get(individualId)
          if (value !== undefined) {
            newValue = value
            valueNotSet = false
          }
        }
        if (this.multipliers.has(individualId)) {
          const value = this.multipliers.get(individualId)
          if (value !== undefined) {
            prevValue = value
            if (valueNotSet) {
              // cas d'une inscription atelier qui ne change pas le nombre
              newValue = value
            }
          }
        }
        return newValue - prevValue
      }
      return 1
    }
    return 0
  }

  public setIndividualMultiplier(individualId: number, multiplier: number): boolean {
    // Combien d'inscriptions pour cette séance?
    // Pour les cantines, une même personne peut réserver plusieurs repas
    const curentMultiplier = this.multipliers.get(individualId) || 0
    if (multiplier === 0) {
      this.newMultipliers.set(individualId, 0)
      this.newMultipliers = new Map(this.newMultipliers)
      return this.resetIndividualInscription(individualId)
    } else {
      this.clearCancellation(individualId)
      const delta: number = multiplier - curentMultiplier
      if (delta) {
        this.newMultipliers.set(individualId, multiplier)
        this.newMultipliers = new Map(this.newMultipliers)
        return this.forceIndividualInscription(individualId)
      } else {
        this.newMultipliers.delete(individualId)
        this.newMultipliers = new Map(this.newMultipliers)
        return this.clearIndividualInscription(individualId)
      }
    }
  }

  public isIndividualInscriptionCancelled(individualId: number): boolean {
    return (
      (
        (this.inscriptionForIndividualIds.indexOf(individualId) >= 0) &&
        (this.cancelledInscriptionForIndividualIds.indexOf(individualId) >= 0)
      ) // annulation
    ) || (
      (this.inscriptionForIndividualIds.indexOf(individualId) >= 0) &&
      this.hasWorkshopInscriptionChanged(individualId) // changement d'atelier
    )
  }

  public isIndividualInscriptionForbidden(individualId: number): string {
    for (const item of this.forbiddenInscriptions) {
      if (item.individualId === individualId) {
        return item.reason
      }
    }
    return ''
  }

  public isIndividualInscriptionDoneByOther(individualId: number): boolean {
    return this.othersInscriptions.indexOf(individualId) >= 0
  }

  public hasMultiplierChanged(individualId: number): boolean {
    if (this.newMultipliers.has(individualId)) {
      const newVal = this.newMultipliers.get(individualId)
      if (newVal === 0) {
        // L'inscription est dans ce cas considéré comme une annulation
        return false
      }
      const prevVal = this.multipliers.get(individualId)
      return newVal !== prevVal
    }
    return false
  }

  public isIndividualInscriptionNew(individualId: number): boolean {
    return (
      (
        (this.inscriptionForIndividualIds.indexOf(individualId) < 0) &&
        (this.newInscriptionForIndividualIds.indexOf(individualId) >= 0)
      ) // nouvelle inscription
    ) || (
      this.hasWorkshopInscriptionChanged(individualId) // changement d'atelier
    ) || (
      this.hasMultiplierChanged(individualId) // changement de nombre
    )
  }

  public hasNewInscriptions(): boolean {
    return (
      this.newInscriptionForIndividualIds.length > 0 // nouvelle inscription
    ) || (
      this.hasWorkshopChanges() // changement d'atelier
    )
  }

  public hasCancellations(): boolean {
    return (
      this.cancelledInscriptionForIndividualIds.length > 0 // annulation
    ) || (
      this.hasWorkshopChanges() // changement d'atelier
    )
  }

  public clearCancellation(individualId: number) {
    const index = this.cancelledInscriptionForIndividualIds.indexOf(individualId)
    if (index >= 0) {
      this.cancelledInscriptionForIndividualIds.splice(index, 1)
    }
  }

  public toggleIndividualInscription(individualId: number, resetOnly: boolean = false): boolean {
    // changer l'inscription : inscrire si-non inscrit et désinscrire si inscrit
    // si resetOnly : ne peut que désinscrire
    let index = this.inscriptionForIndividualIds.indexOf(individualId)
    if (index >= 0) {
      // Has an existing inscription
      index = this.cancelledInscriptionForIndividualIds.indexOf(individualId)
      if (index >= 0) {
        if (!resetOnly) {
          // already cancelled : delete the cancellation
          this.cancelledInscriptionForIndividualIds.splice(index, 1)
          return true
        }
      } else {
        // add a cancellation
        this.cancelledInscriptionForIndividualIds.push(individualId)
        return false
      }
    } else {
      index = this.newInscriptionForIndividualIds.indexOf(individualId)
      if (index >= 0) {
        // already existing : remove the new inscription
        this.newInscriptionForIndividualIds.splice(index, 1)
        return false
      } else {
        if (!resetOnly) {
          // not existing : add a new inscription
          this.newInscriptionForIndividualIds.push(individualId)
          return true
        }
      }
    }
    return false
  }

  public forceIndividualInscription(individualId: number): boolean {
    // force une nouvelle inscription même si déjà inscrit
    const index = this.newInscriptionForIndividualIds.indexOf(individualId)
    if (index >= 0) {
      // already existing : nothing to do
    } else {
      this.newInscriptionForIndividualIds.push(individualId)
    }
    return true
  }

  public clearIndividualInscription(individualId: number): boolean {
    // force une nouvelle inscription même si déjà inscrit
    const index = this.newInscriptionForIndividualIds.indexOf(individualId)
    if (index >= 0) {
      // supprime
      this.newInscriptionForIndividualIds.splice(index, 1)
      return false
    } else {
      return false
    }
  }

  public resetIndividualInscription(individualId: number): boolean {
    return this.toggleIndividualInscription(individualId, true)
  }

  public setIndividualInscription(individualId: number): boolean {
    if (!this.doesIndividualHaveInscription(individualId)) {
      return this.toggleIndividualInscription(individualId)
    }
    return true
  }

  public reinit(updatedSeance: EntitySeance) {
    this.inscriptionForIndividualIds = updatedSeance.inscriptionForIndividualIds
    this.originalWorkshopInscriptions = updatedSeance.originalWorkshopInscriptions
    this.multipliers = updatedSeance.multipliers
    this.resetChanges()
  }

  public resetChanges(): void {
    // effacer tous lees changements
    this.newInscriptionForIndividualIds = []
    this.cancelledInscriptionForIndividualIds = []
    this.workshopInscriptions = {}
    for (const [key, value] of Object.entries(this.originalWorkshopInscriptions)) {
      this.workshopInscriptions[key] = value
    }
    this.newMultipliers = new Map()
  }

  public setWorkshopInscription(individualId: number, moment: WorkshopMoments, workshopId: number): void {
    if (individualId in this.workshopInscriptions) {
      const elements = this.workshopInscriptions[individualId]
      const indexes = []
      for (let index = elements.length - 1; index >= 0; index--) {
        const elt = elements[index]
        if (elt.moment === moment) {
          indexes.push(index)
        }
      }
      for (const index2 of indexes) {
        elements.splice(index2, 1)
      }
    } else {
      this.workshopInscriptions[individualId] = []
    }
    const newWorkshopInscription = makeWorkshopInscription({
      workshop: workshopId, moment: moment,
    })
    this.workshopInscriptions[individualId].push(newWorkshopInscription)
    this.workshopInscriptions = { ...this.workshopInscriptions, }
  }

  public getWorkshopMoments() {
    const moments = [
      WorkshopMoments.None,
      WorkshopMoments.Morning,
      WorkshopMoments.Lunch,
      WorkshopMoments.Afternoon,
      WorkshopMoments.Evening
    ]
    const workshopMoments = this.workshops.map(elt => elt.moment)
    return moments.filter(elt => (workshopMoments.indexOf(elt) >= 0))
  }

  public getWorkshop(workshopId: number): Workshop|null {
    if (workshopId > 0) {
      for (const workshop of this.workshops) {
        if (workshop.id === workshopId) {
          return workshop
        }
      }
    }
    return null
  }

  public isAvailableForIndividual(individualId: number): boolean {
    return this.availableForIndividualIds.indexOf(individualId) >= 0
  }

  public isBlockedForSeance(individualId: number): boolean {
    return this.blockedForIndividualIds.indexOf(individualId) >= 0
  }

  private static _getWorkshopInscriptions(workshopInscriptionsObj: any, individualId: number): WorkshopInscription[] {
    if (individualId in workshopInscriptionsObj) {
      const inscriptions = workshopInscriptionsObj[individualId] || []
      return inscriptions.filter((elt: WorkshopInscription) => (elt.workshop > 0))
    }
    return []
  }

  public getWorkshopInscriptions(individualId: number): WorkshopInscription[] {
    return EntitySeance._getWorkshopInscriptions(this.workshopInscriptions, individualId)
  }

  public getOriginalWorkshopInscriptions(individualId: number): WorkshopInscription[] {
    return EntitySeance._getWorkshopInscriptions(this.originalWorkshopInscriptions, individualId)
  }

  public hasChanged(): boolean {
    return this.hasNewInscriptions() || this.hasCancellations()
  }
  public isSameMoment(other: EntitySeance): boolean {
    return (
      (this.morning === other.morning) &&
      (this.lunch === other.lunch) &&
      (this.picnic === other.picnic) &&
      (this.afternoon === other.afternoon) &&
      (this.evening === other.evening)
    )
  }
}

export function makeEntitySeance(jsonData: any = null): EntitySeance {
  if (!jsonData) {
    jsonData = {}
  }
  const individualIds = jsonData.individuals || []
  const blockedIndividualIds = jsonData.blocked_individuals || []
  const workshops: Workshop[] = (jsonData.workshops || []).map((elt: any) => makeWorkshop(elt))
  const jsonWorkshopInscriptions = jsonData.workshop_inscriptions || {}
  const workshopInscriptions: any = {}
  const originalWorkshopInscriptions: any = {}
  for (const individualId in individualIds) {
    workshopInscriptions[individualId] = []
    originalWorkshopInscriptions[individualId] = []
  }
  for (const key in jsonWorkshopInscriptions) {
    const items = jsonWorkshopInscriptions[key] || []
    workshopInscriptions[key] = items.map((item: any) => makeWorkshopInscription(item))
    originalWorkshopInscriptions[key] = items.map((item: any) => makeWorkshopInscription(item))
  }
  const forbiddenInscriptions = jsonData.forbidden_inscriptions || []
  const multipliers = new Map()
  if (jsonData.multipliers) {
    for (const [key, value] of Object.entries(jsonData.multipliers)) {
      multipliers.set(+key, value)
    }
  }
  return new EntitySeance(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.base_name || '',
    jsonData.code || '',
    makeSeancePeriod(jsonData.period),
    makeSeanceType(jsonData.seance_type),
    makeYouthHome(jsonData.youth_home),
    jsonData.date || null,
    !!jsonData.morning,
    !!jsonData.lunch,
    !!jsonData.picnic,
    !!jsonData.afternoon,
    !!jsonData.evening,
    jsonData.comments || '',
    (+jsonData.duration) || 0,
    jsonData.parent || null,
    jsonData.children ? jsonData.children : [],
    workshops,
    jsonData.order || 0,
    jsonData.alias || '',
    !!jsonData.fixed_fee,
    !!jsonData.fixed_fee_auto,
    !!jsonData.is_reward,
    jsonData.excursion || Excursion.None,
    jsonData.preference_group || '',
    jsonData.lock || SeanceLock.None,
    !!jsonData.force_tariff_type,
    jsonData.custom_tariff_type || TariffType.SEANCE_TARIFF,
    jsonData.inscription_ids || {},
    individualIds,
    blockedIndividualIds,
    jsonData.inscriptions || [],
    [],
    [],
    workshopInscriptions,
    originalWorkshopInscriptions,
    forbiddenInscriptions.map((elt: any) => makeForbiddenInscription(elt)),
    jsonData.others_inscriptions || [],
    jsonData.dependencies || [],
    !!jsonData.allow_discount_on_free_price,
    multipliers,
    new Map(),
    jsonData.day_id || 0
  )
}

export class Seance extends BaseSeance {
  constructor(
    public id: number,
    public name: string,
    public baseName: string,
    public code: string,
    public period: SeancePeriod,
    public seanceType: SeanceType,
    public youthHome: YouthHome,
    public date: Date,
    public morning: boolean,
    public lunch: boolean,
    public picnic: boolean,
    public afternoon: boolean,
    public evening: boolean,
    public comments: boolean,
    public duration: number,
    public citySpecific: CitySpecific,
    public tariff: number,
    public parent: number, // si inscription pour la séance parent alors
    public children: number[], // on s'inscrit aussi automatiquement pour les enfants
    public welfare: SeanceWelfare[],
    public workshops: Workshop[],
    public order: number,
    public alias: string,
    public fixedFee: boolean,
    public fixedFeeAuto: boolean,
    public isReward: boolean,
    public excursion: Excursion,
    public preferenceGroup: string,
    public lock: SeanceLock,
    public forceTariffType: boolean,
    public customTariffType: TariffType,
    public globalLimits: number,
    public seanceLimits: number,
    public seanceAnalyticAccount: SeanceAnalyticAccount,
    public scale: number
  ) {
    super(
      id, name, baseName, code, period, seanceType, youthHome, date, morning, lunch, picnic, afternoon, evening,
      comments, duration, parent, children, workshops, order, alias, fixedFee, fixedFeeAuto, isReward, excursion,
      preferenceGroup, lock, forceTariffType, customTariffType
    )
  }

  public citySpecificDisplay(): string {
    return citySpecificDisplay(this.citySpecific)
  }

  public toEntitySeance(individual: Individual): EntitySeance {
    return new EntitySeance(
      this.id,
      this.name,
      this.baseName,
      this.code,
      this.period,
      this.seanceType,
      this.youthHome,
      this.date,
      this.morning,
      this.lunch,
      this.picnic,
      this.afternoon,
      this.evening,
      this.comments,
      this.duration,
      this.parent,
      this.children,
      this.workshops,
      this.order,
      this.alias || '',
      this.fixedFee,
      this.fixedFeeAuto,
      this.isReward,
      this.excursion,
      this.preferenceGroup,
      this.lock,
      this.forceTariffType,
      this.customTariffType,
      {},
      [individual.id],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      false
    )
  }
}

export function makeSeance(jsonData: any = null): Seance {
  if (!jsonData) {
    jsonData = {}
  }
  const citySpecific = (jsonData.city_specific === undefined) ? CitySpecific.NA : jsonData.city_specific
  const workshops: Workshop[] = (jsonData.workshops || []).map((elt: any) => makeWorkshop(elt))
  const welfare: SeanceWelfare[] = (jsonData.welfare || []).map((elt: any) => makeSeanceWelfare(elt))
  return new Seance(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.base_name || '',
    jsonData.code || '',
    makeSeancePeriod(jsonData.period),
    makeSeanceType(jsonData.seance_type),
    makeYouthHome(jsonData.youth_home),
    jsonData.date || null,
    !!jsonData.morning,
    !!jsonData.lunch,
    !!jsonData.picnic,
    !!jsonData.afternoon,
    !!jsonData.evening,
    jsonData.comments || '',
    (+jsonData.duration) || 0,
    citySpecific || 0,
    jsonData.tariff || 0,
    jsonData.parent || null,
    jsonData.children ? jsonData.children : [],
    welfare,
    workshops,
    jsonData.order || 0,
    jsonData.alias || '',
    !!jsonData.fixed_fee,
    !!jsonData.fixed_fee_auto,
    !!jsonData.is_reward,
    jsonData.excursion || Excursion.None,
    jsonData.preference_group || '',
    jsonData.lock || SeanceLock.None,
    !!jsonData.force_tariff_type,
    jsonData.custom_tariff_type || TariffType.SEANCE_TARIFF,
    jsonData.global_limits || 0,
    jsonData.seance_limits || 0,
    makeSeanceAnalyticAccount(jsonData.seance_analytic_account),
    jsonData.scale || 0
  )
}

export class SeanceTemplate {
  constructor(
    public id: number,
    public name: string,
    public code: string,
    public seanceType: SeanceType,
    public youthHomeNumber: number,
    public morning: boolean,
    public lunch: boolean,
    public picnic: boolean,
    public afternoon: boolean,
    public evening: boolean,
    public comments: boolean,
    public duration: number,
    public citySpecific: CitySpecific,
    public tariff: number,
    public welfare: SeanceWelfare[],
    public workshops: Workshop[],
    public scale: number,
    public extraYouthHomeNumbers: string,
    public availableForYouthHomeNumbers: number[],
    public daysNumber: number,
    public order: number,
    public alias: string,
    public fixedFee: boolean,
    public fixedFeeAuto: boolean,
    public fixedFeeCodes: string,
    public isReward: boolean,
    public noStats: boolean,
    public excursion: Excursion,
    public preferenceGroup: string,
    public forceTariffType: boolean,
    public customTariffType: TariffType,
    public allowForceFirstAsParent: boolean
  ) {
  }

  public getCodeName(): string {
    // code d'une séance
    return this.code
  }

  public citySpecificDisplay(): string {
    return citySpecificDisplay(this.citySpecific)
  }

  public isAvailableFor(youthHomeNumber: number): boolean {
    return (this.availableForYouthHomeNumbers.indexOf(youthHomeNumber) >= 0)
  }
}

export function makeSeanceTemplate(jsonData: any = null): SeanceTemplate {
  if (!jsonData) {
    jsonData = {}
  }
  let youthHomeNumbers = []
  if (jsonData.available_for_youth_home_numbers) {
    youthHomeNumbers = jsonData.available_for_youth_home_numbers
  } else {
    youthHomeNumbers = [jsonData.youth_home_number]
  }
  const workshops: Workshop[] = (jsonData.workshops || []).map((elt: any) => makeWorkshop(elt))
  const welfare: SeanceWelfare[] = (jsonData.welfare || []).map((elt: any) => makeSeanceWelfare(elt))
  return new SeanceTemplate(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.code || '',
    makeSeanceType(jsonData.seance_type),
    jsonData.youth_home_number,
    !!jsonData.morning,
    !!jsonData.lunch,
    !!jsonData.picnic,
    !!jsonData.afternoon,
    !!jsonData.evening,
    jsonData.comments || '',
    (+jsonData.duration) || 0,
    jsonData.city_specific,
    jsonData.tariff || 0,
    welfare,
    workshops,
    jsonData.scale || 0,
    jsonData.extra_youth_home_numbers || '',
    youthHomeNumbers,
    jsonData.days_number ? jsonData.days_number : 1,
    jsonData.order || 0,
    jsonData.alias || '',
    !!jsonData.fixed_fee,
    !!jsonData.fixed_fee_auto,
    jsonData.fixed_fee_codes || '',
    !!jsonData.is_reward,
    !!jsonData.no_stats,
    jsonData.excursion || Excursion.None,
    jsonData.preference_group || '',
    !!jsonData.force_tariff_type,
    jsonData.custom_tariff_type || TariffType.SEANCE_TARIFF,
    !!jsonData.allow_force_first_as_parent
  )
}

export class SeanceInscription {
  constructor(
    public id: number,
    public seance: EntitySeance,
    public individual: Individual,
    public arrivedAt: string = '',
    public leftAt: string = '',
    public arrivedAt2: string = '',
    public leftAt2: string = '',
    public arrivedAt3: string = '',
    public leftAt3: string = '',
    public ageGroup: AgeGroup|null = null,
    public createdOn: Date|null = null,
    public createdBy: string = '',
    public cancelledOn: Date|null = null,
    public updatedOn: Date|null = null,
    public cancelled: boolean = false,
    public acceptedOn: Date|null = null,
    public acceptedBy: string = '',
    public refusedOn: Date|null = null,
    public refusedBy: string = '',
    public cancelledBy: string = '',
    public refused: boolean = false,
    public waiting: boolean = false,
    public confirmed: boolean = false,
    public absence: boolean = false,
    public invoiced: boolean = false,
    public isPaid: boolean = false,
    public isFree: boolean = false,
    public parents: Individual[] = [],
    public entity: Entity | null,
    public workshops: Workshop[] = [],
    public sale: SaleWithInvoice | null,
    public extraSales: SaleWithInvoice[] = [],
    public reward: Reward | null,
    public paidBy: number,
    public multiplier: number, // plusieurs inscriptions (exemple: cantine)
    public preference: number,
    public familyLevel: number,
    public comments: string,
    public clockingInProgress: boolean = false
  ) {
  }

  public getKey(): string {
    return '' + this.seance.id + ':' + this.individual.id
  }

  public showArrivedAt(openingHours: OpeningHours): boolean {
    if (openingHours.openingAt) {
      return this.seance.morning
    }
    return false
  }

  public showLeftAt(openingHours: OpeningHours): boolean {
    if (openingHours.closingAt) {
      // seulement matin sans journée entière
      return this.seance.morning && !(this.seance.lunch && this.seance.afternoon)
    }
    return false
  }

  public showArrivedAt2(openingHours: OpeningHours): boolean {
    return !!(
      openingHours.openingAt2 &&
      // seulement apres-midi sans journée entière
      this.seance.afternoon && !(this.seance.lunch && this.seance.morning)
    )
  }

  public showLeftAt2(openingHours: OpeningHours): boolean {
    return !!(openingHours.closingAt2 && this.seance.afternoon)
  }

  public showArrivedAt3(openingHours: OpeningHours): boolean {
    // soirée
    return !!(openingHours.openingAt3 && this.seance.evening)
  }

  public showLeftAt3(openingHours: OpeningHours): boolean {
    // soirée
    return !!(openingHours.closingAt3 && this.seance.evening)
  }

  public isClockingDone(openingHours: OpeningHours): boolean {
    if (this.showArrivedAt(openingHours) && !this.arrivedAt) {
      return false
    }
    if (this.showLeftAt(openingHours) && !this.leftAt) {
      return false
    }
    if (this.showArrivedAt2(openingHours) && !this.arrivedAt2) {
      return false
    }
    if (this.showLeftAt2(openingHours) && !this.leftAt2) {
      return false
    }
    if (this.showArrivedAt3(openingHours) && !this.arrivedAt3) {
      return false
    }
    if (this.showLeftAt3(openingHours) && !this.leftAt3) {
      return false
    }
    return !(
      !openingHours.openingAt &&
      !openingHours.closingAt &&
      !openingHours.openingAt2 &&
      !openingHours.closingAt2 &&
      !openingHours.openingAt3 &&
      !openingHours.closingAt3
    )
  }

  public isArrived(openingHours: OpeningHours): boolean {
    let arrivedAt = true
    let arrivedAt2 = true
    let arrivedAt3 = true
    if (this.clockingInProgress) {
      // Pointage en cours: on ne cache pas la ligne
      return false
    }
    if (this.showArrivedAt(openingHours)) {
      // Si pointage Arrivé Matin et pas de pointage -> Pas arrivé
      arrivedAt = !!this.arrivedAt
    }
    if (this.showArrivedAt2(openingHours)) {
      // Si pointage Arrivé Après-midi et pas de pointage -> Pas arrivé
      arrivedAt2 = !!this.arrivedAt2
    }
    if (this.showLeftAt(openingHours) && (!this.leftAt)) {
      // Si pointage matin et pas encore parti
      // alors forcément arrivé
      return arrivedAt
    }
    if (this.showArrivedAt3(openingHours)) {
      // Si pointage Arrivé Soir et pas de pointage -> Pas arrivé
      arrivedAt3 = !!this.arrivedAt3
    }
    return arrivedAt && arrivedAt2 && arrivedAt3
  }

  public isNotArrived(openingHours: OpeningHours): boolean {
    if (this.clockingInProgress) {
      // Pointage en cours: on ne cache pas la ligne
      return false
    }
    let arrivedAt = true
    let arrivedAt2 = true
    let arrivedAt3 = true
    if (this.showArrivedAt(openingHours)) {
      // Si pointage Arrivé Matin et pas de pointage -> Pas arrivé
      arrivedAt = !!this.arrivedAt
    }
    if (this.showArrivedAt2(openingHours)) {
      // Si pointage Arrivé Après-midi et pas de pointage -> Pas arrivé
      arrivedAt2 = !!this.arrivedAt2
    }
    if (this.showArrivedAt3(openingHours)) {
      // Si pointage Arrivé Soir et pas de pointage -> Pas arrivé
      arrivedAt3 = !!this.arrivedAt3
    }
    return !arrivedAt || !arrivedAt2 || !arrivedAt3
  }

  public isHere(openingHours: OpeningHours): boolean {
    if (this.clockingInProgress) {
      // Pointage en cours: on ne cache pas la ligne
      return true
    }

    if (this.showArrivedAt(openingHours) && this.arrivedAt) {
      // Arrivée le matin
      if (this.showLeftAt(openingHours) && !this.leftAt) {
        // Demi-journée sans départ à midi
        return true
      } else if (!this.showArrivedAt(openingHours) && this.showLeftAt2(openingHours) && !this.leftAt2) {
        // Journée sans départ le soir
        return true
      }
    }

    if (this.showArrivedAt2(openingHours) && this.arrivedAt2) {
      // Arrivée demi-journée
      if (this.showLeftAt2(openingHours) && !this.leftAt2) {
        // sans départ le soir
        return true
      }
    }

    if (this.showArrivedAt3(openingHours) && this.arrivedAt3) {
      // Arrivée soirée
      if (this.showLeftAt3(openingHours) && !this.leftAt3) {
        return true
      }
    }

    return false
  }

  public cancelledOnAsStr(): string {
    if (this.cancelledOn) {
      let text = dateToString(this.cancelledOn)
      if (this.cancelledBy) {
        text += ' par ' + this.cancelledBy
      }
      return text
    }
    return ''
  }

  public refusedOnAsStr(): string {
    if (this.refusedOn) {
      return dateToString(this.refusedOn)
    }
    return ''
  }
}

export function newSeanceInscription(id: number, seance: EntitySeance, individual: Individual): SeanceInscription {
  return new SeanceInscription(
    id, seance, individual, '', '', '', '', '', '', null, null,
    '', null, null, false, null, '', null, '',
    '', false, false, false, false, false, false, false, [], null,
    [], null, [], null, 0, 0, 0, 0, ''
  )
}

export class AgeGroup {
  constructor(
    public id: number,
    public startAge: number,
    public endAge: number,
    public name: string,
    public date: string,
    public seanceId: number
  ) {
  }
}

export function makeAgeGroup(jsonData: any = null): AgeGroup {
  if (!jsonData) {
    jsonData = {}
  }
  return new AgeGroup(
    jsonData.id || 0,
    jsonData.start_age || 0,
    jsonData.end_age || 0,
    jsonData.name || '',
    jsonData.date || '',
    +(jsonData.seance || 0)
  )
}

export function getAgeAtSeance(individual: Individual, seanceDate: any): number {
  return moment(seanceDate).diff(individual.birthDate, 'years')
}

export function getAgeYear(individual: Individual, year: number): number {
  if (individual.birthDate) {
    return year - moment(individual.birthDate).year()
  } else {
    return 0
  }
}

export function getAgeForGroup(individual: Individual, child: Child, seanceDate: any, youthHome: YouthHome): number {
  let referenceDate = seanceDate
  if (youthHome.ageRule === YouthHomeAgeRule.CivilYear) {
    // Un enfant né en 2015 a 9 ans au cours de l'année 2024 donc au 31/12/2024
    referenceDate = '' + youthHome.schoolYear.startYear + '-12-31'
  } else if (youthHome.ageRule === YouthHomeAgeRule.SchoolLevel) {
    // âge selon le niveau scolaire
    if (child && child.schoolLevel && child.schoolLevel.id && child.schoolLevel.age) {
      return child.schoolLevel.age
    } else {
      // par défaut en année civile
      referenceDate = '' + youthHome.schoolYear.startYear + '-12-31'
    }
  }
  return getAgeAtSeance(individual, referenceDate)
}

export function getCustomAge(child: Child): number {
  if (child.ageGroup) {
    return child.ageGroup
  } else {
    return 0
  }
}

export function getAgeGroup(individual: Individual, child: Child, seanceDate: any, youthHome: YouthHome): number {
  if (child.ageGroup) {
    return child.ageGroup
  } else {
    // calculate age at this date
    return getAgeForGroup(individual, child, seanceDate, youthHome)
  }
}

export function makeSeanceInscription(jsonData: any = null): SeanceInscription {
  if (!jsonData) {
    jsonData = {}
  }
  const parents = jsonData.parents || []
  const workshops = jsonData.workshops || []
  const extraSales = jsonData.extra_sales || []
  return new SeanceInscription(
    jsonData.id || 0,
    makeEntitySeance(jsonData.seance),
    makeIndividual(jsonData.individual, jsonData.entity ? jsonData.entity.id : 0),
    jsonData.arrived_at || '',
    jsonData.left_at || '',
    jsonData.arrived_at2 || '',
    jsonData.left_at2 || '',
    jsonData.arrived_at3 || '',
    jsonData.left_at3 || '',
    jsonData.age_group ? makeAgeGroup(jsonData.age_group) : null,
    jsonData.created_on || null,
    jsonData.created_by || null,
    jsonData.cancelled_on || null,
    jsonData.updated_on || null,
    !!jsonData.cancelled,
    jsonData.accepted_on || null,
    jsonData.accepted_by || '',
    jsonData.refused_on || null,
    jsonData.refused_by || '',
    jsonData.cancelled_by || '',
    !!jsonData.refused,
    !!jsonData.waiting,
    !!jsonData.confirmed,
    !!jsonData.absence,
    !!jsonData.invoiced,
    !!jsonData.is_paid,
    !!jsonData.is_free,
    parents.map((elt: any) => makeIndividual(elt)),
    jsonData.entity ? makeEntity(jsonData.entity) : null,
    workshops.map((elt: any) => makeWorkshop(elt)),
    jsonData.sale ? makeSaleWithInvoice(jsonData.sale) : null,
    extraSales.map((elt: any) => makeSaleWithInvoice(elt)),
    jsonData.reward ? makeReward(jsonData.reward) : null,
    jsonData.paid_by || 0,
    jsonData.multiplier || 0,
    jsonData.preference || 0,
    jsonData.family_level || 0,
    jsonData.comments || ''
  )
}

export class InscriptionRule {
  constructor(
    public id: number,
    public seanceCodes: string[],
    public isMain: boolean,
    public order: number,
    public daily: boolean,
    public regex: string
  ) {
  }

  public match(code: string): boolean {
    if (this.regex) {
      return this.regex.match(code) !== null
    } else {
      return this.seanceCodes.indexOf(code) >= 0
    }
  }
}

export function makeInscriptionRule(jsonData: any = null): InscriptionRule {
  if (!jsonData) {
    jsonData = {}
  }
  let regex = ''
  let codes = []
  if (jsonData.is_regex) {
    regex = jsonData.seance_codes
  } else {
    codes = jsonData.seance_codes.split(';')
  }
  return new InscriptionRule(
    jsonData.id || 0,
    codes,
    !!jsonData.is_main,
    jsonData.order || 0,
    !!jsonData.daily,
    regex
  )
}

export class DailyListField {
  constructor(
    public id: number,
    public text: string,
    public field: number,
    public trueOnly: boolean,
    public falseOnly: boolean,
    public allowComments: boolean,
    public showInCol: boolean,
    public showAsMark: boolean,
    public showAsMarkAndText: boolean,
    public showCounter: boolean
  ) {
  }
}

export function makeDailyListField(jsonData: any = null): DailyListField {
  if (!jsonData) {
    jsonData = {}
  }
  return new DailyListField(
    jsonData.id || 0,
    jsonData.text,
    jsonData.field,
    !!jsonData.true_only,
    !!jsonData.false_only,
    jsonData.allow_comments,
    !!jsonData.show_in_col,
    !!jsonData.show_as_mark,
    !!jsonData.show_as_mark_and_text,
    !!jsonData.show_counter
  )
}

export class AgeGroupSeanceLimit {
  constructor(
    public id: number,
    public groupId: number,
    public groupName: string,
    public startAge: number,
    public morningMax: number,
    public lunchMax: number,
    public afternoonMax: number,
    public isBlocked: boolean
  ) {
  }

  public isValid() {
    if (this.isBlocked) {
      return true
    } else {
      return (isNumber(this.morningMax) && isNumber(this.lunchMax) && isNumber(this.afternoonMax))
    }
  }
}

export enum LimitType {
  LIMIT_INVALID = 0,
  LIMIT_SEANCE_TYPE_AND_PERIOD = 40,
  LIMIT_DAY = 50,
  LIMIT_EXCURSION = 55,
  LIMIT_SEANCE = 60
}

export class Limit {
  constructor(
    public id: number,
    public label: string,
    public limit: number,
    public inscriptions: number = 0
  ) {
  }

  public shortLabel() {
    const words = this.label.split('-')
    const wordInitials = words.filter(elt => elt).map(elt => elt[0].toUpperCase())
    return wordInitials.join('')
  }
}

export function getLimits(limits: number[], inscriptions: number[]): Limit[] {
  if (limits && inscriptions) {
    const allLimits = []
    if ((limits.length >= 3 && (inscriptions.length >= 3))) {
      if (limits[0] > 0) {
        allLimits.push(new Limit(1, 'Matin', limits[0], inscriptions[0]))
      }
      if (limits[1] > 0) {
        allLimits.push(new Limit(2, 'Repas', limits[1], inscriptions[1]))
      }
      if (limits[2] > 0) {
        allLimits.push(new Limit(3, 'Après-midi', limits[2], inscriptions[2]))
      }
    } else if ((limits.length === 1 && (inscriptions.length === 1))) {
      if (limits[0] > 0) {
        allLimits.push(new Limit(1, '', limits[0], inscriptions[0]))
      }
    }
    return allLimits
  }
  return []
}

export function getLimitTypeName(limitType: LimitType, seanceTypeName: string = '', periodName: string = ''): string {
  switch (limitType) {
    case LimitType.LIMIT_SEANCE:
      return 'Seulement pour la séance'
    case LimitType.LIMIT_EXCURSION:
      return 'Pour une sortie ou un séjour'
    case LimitType.LIMIT_DAY:
      return 'Seulement pour la journée'
    case LimitType.LIMIT_SEANCE_TYPE_AND_PERIOD:
      return 'Pour toutes les séances de type XXX et de période YYY'.replace(
        'XXX', seanceTypeName
      ).replace(
        'YYY', periodName
      )
    default:
      return 'A définir'
  }
}

export function getLimitTypeKind(limitType: LimitType): string {
  switch (limitType) {
    case LimitType.LIMIT_SEANCE:
      return 'Séance'
    case LimitType.LIMIT_EXCURSION:
      return 'Sortie ou un séjour'
    case LimitType.LIMIT_DAY:
      return 'Journée'
    case LimitType.LIMIT_SEANCE_TYPE_AND_PERIOD:
      return 'Journée'
    default:
      return 'A définir'
  }
}

export function getLimitSeveralMoments(limitType: LimitType): boolean {
  switch (limitType) {
    case LimitType.LIMIT_SEANCE:
      return false
    case LimitType.LIMIT_EXCURSION:
      return false
    case LimitType.LIMIT_DAY:
      return true
    case LimitType.LIMIT_SEANCE_TYPE_AND_PERIOD:
      return true
    default:
      return true
  }
}

export function getLimitTypes(isExcursion: boolean): LimitType[] {
  const limitTypes = [
    LimitType.LIMIT_SEANCE,
    LimitType.LIMIT_DAY,
    LimitType.LIMIT_SEANCE_TYPE_AND_PERIOD
  ]
  if (isExcursion) {
    limitTypes.push(LimitType.LIMIT_EXCURSION)
  }
  return limitTypes.sort()
}

export class SeanceLimit {
  constructor(
    public id: number,
    public limitType: LimitType,
    public seanceId: number,
    public seanceName: string,
    public day: string,
    public periodId: number,
    public seanceTypeId: number,
    public overrideOthers: boolean,
    public morningMax: number,
    public lunchMax: number,
    public afternoonMax: number,
    public isBlocked: boolean,
    public ageRuleName: string,
    public ageGroups: AgeGroupSeanceLimit[]
  ) {
  }

  public getMoments(): string[] {
    if (this.limitType === LimitType.LIMIT_SEANCE) {
      return ['morning']
    } else {
      return ['morning', 'lunch', 'afternoon']
    }
  }

  public getSeanceLimits(seance: Seance, ageGroup: AgeGroup|null): Limit[] {
    let matches: boolean = false
    switch (this.limitType) {
      case LimitType.LIMIT_SEANCE:
        matches = (seance.id === this.seanceId)
        break
      case LimitType.LIMIT_EXCURSION:
        matches = (
          seance.isExcursion() &&
          (moment(seance.date).format('yyyy-mm-dd') === this.day) &&
          seance.name === this.seanceName
        )
        break
      case LimitType.LIMIT_DAY:
        matches = (moment(seance.date).format('yyyy-mm-dd') === this.day)
        break
      case LimitType.LIMIT_SEANCE_TYPE_AND_PERIOD:
        matches = ((seance.seanceType.id === this.seanceTypeId) && (seance.period.id === this.periodId))
        break
      case LimitType.LIMIT_INVALID:
        matches = false
        break
    }
    if (matches) {
      if (ageGroup) {
        for (const elt of this.ageGroups) {
          if (elt.startAge === ageGroup.startAge) {
            return SeanceLimit.asLimits([elt.morningMax, elt.lunchMax, elt.afternoonMax])
          }
        }
      } else {
        // si 0 : on considère que ce n'est pas une limite
        if (sum([this.morningMax, this.lunchMax, this.afternoonMax]) > 0) {
          return SeanceLimit.asLimits([this.morningMax, this.lunchMax, this.afternoonMax])
        }
      }
    }
    return []
  }

  public getAgeGroupLimits(ageGroup: AgeGroup): Limit[] {
    let matches: boolean = true
    switch (this.limitType) {
      case LimitType.LIMIT_SEANCE:
        matches = false
        break
      case LimitType.LIMIT_INVALID:
        matches = false
        break
    }
    if (matches) {
      for (const elt of this.ageGroups) {
        if (elt.startAge === ageGroup.startAge) {
          return SeanceLimit.asLimits([elt.morningMax, elt.lunchMax, elt.afternoonMax])
        }
      }
    }
    return []
  }

  public getDailyLimits(): Limit[] {
    let matches: boolean = true
    switch (this.limitType) {
      case LimitType.LIMIT_SEANCE:
        matches = false
        break
      case LimitType.LIMIT_INVALID:
        matches = false
        break
    }
    if (matches) {
      if (sum([this.morningMax, this.lunchMax, this.afternoonMax]) > 0) {
        return SeanceLimit.asLimits([this.morningMax, this.lunchMax, this.afternoonMax])
      }
    }
    return []
  }

  private static asLimits(values: number[]): Limit[] {
    if (values.length >= 3) {
      return [
        new Limit(1, 'Matin', values[0]),
        new Limit(2, 'Repas', values[1]),
        new Limit(3, 'Après-midi', values[2])
      ]
    }
    return []
  }

  public getTypeName(seanceTypeName: string = '', periodName: string = ''): string {
    return getLimitTypeName(this.limitType, seanceTypeName, periodName)
  }

  public getScopeName(): string {
    const scopes: string[] = []
    if (this.isBlocked) {
      scopes.push('Inscriptions interdites pour tous')
    } else {
      const limitKind = getLimitTypeKind(this.limitType)
      if (getLimitSeveralMoments(this.limitType)) {
        if ((this.morningMax + this.lunchMax + this.afternoonMax) > 0) {
          if ((this.morningMax === this.lunchMax) && (this.morningMax === this.afternoonMax)) {
            scopes.push(limitKind + ': ' + this.morningMax)
          } else {
            let values = [this.morningMax, this.lunchMax, this.afternoonMax].map(elt => '' + elt)
            scopes.push(limitKind + ': ' + values.join('|'))
          }
        }
      } else {
        if (this.morningMax) {
          scopes.push(limitKind + ': ' + this.morningMax)
        }
      }
      for (const ageGroup of this.ageGroups) {
        if (ageGroup.isBlocked) {
          scopes.push(ageGroup.groupName + ': inscriptions interdites')
        } else {
          if ((ageGroup.morningMax + ageGroup.lunchMax + ageGroup.afternoonMax) > 0) {
            if ((ageGroup.morningMax === ageGroup.lunchMax) && (ageGroup.morningMax === ageGroup.afternoonMax)) {
              scopes.push(ageGroup.groupName + ': ' + ageGroup.morningMax)
            } else {
              let values = [ageGroup.morningMax, ageGroup.lunchMax, ageGroup.afternoonMax].map(elt => '' + elt)
              scopes.push(ageGroup.groupName + ': ' + values.join('|'))
            }
          }
        }
      }
    }
    if (scopes.length === 0) {
      return 'Aucune limite'
    } else {
      return scopes.join('\n')
    }
  }

  public isValid() {
    if (this.limitType === LimitType.LIMIT_INVALID) {
      return false
    }
    if (!(isNumber(this.morningMax) && isNumber(this.lunchMax) && isNumber(this.afternoonMax))) {
      return false
    }
    for (const ageGroup of this.ageGroups) {
      if (!ageGroup.isValid()) {
        return false
      }
    }
    return true
  }

  public isExcursion() {
    return this.limitType === LimitType.LIMIT_EXCURSION
  }
}

export function makeAgeGroupSeanceLimit(jsonData: any = null): AgeGroupSeanceLimit {
  if (!jsonData) {
    jsonData = {}
  }
  return new AgeGroupSeanceLimit(
    jsonData.id || 0,
    jsonData.group_id || 0,
    jsonData.group_name || '',
    jsonData.start_age || 0,
    jsonData.morning_max || 0,
    jsonData.lunch_max || 0,
    jsonData.afternoon_max || 0,
    !!jsonData.is_blocked
  )
}

export function makeSeanceLimit(jsonData: any = null): SeanceLimit {
  if (!jsonData) {
    jsonData = {}
  }
  const ageGroups = jsonData.age_groups || []
  return new SeanceLimit(
    jsonData.id || 0,
    jsonData.limit_type || LimitType.LIMIT_INVALID,
    jsonData.seance ? jsonData.seance.id : 0,
    jsonData.seance ? jsonData.seance.name : '',
    (jsonData.seance && jsonData.seance.excursion !== Excursion.None) ? jsonData.seance.date : (jsonData.day || ''),
    jsonData.period || 0,
    jsonData.seance_type || 0,
    !!jsonData.override_others,
    jsonData.morning_max || 0,
    jsonData.lunch_max || 0,
    jsonData.afternoon_max || 0,
    !!jsonData.is_blocked,
    jsonData.age_rule_name || '',
    ageGroups.map((elt: any) => makeAgeGroupSeanceLimit(elt))
  )
}

export class SeanceLimits {
  constructor(
    public limits: SeanceLimit[]
  ) {
  }

  public getSeanceLimits(seance: Seance, ageGroup: AgeGroup|null): Limit[] {
    for (const limit of this.limits) {
      const limits: Limit[] = limit.getSeanceLimits(seance, ageGroup)
      if (limits.length > 0) {
        return limits
      }
    }
    return []
  }
  public getAgeGroupLimits(ageGroup: AgeGroup): Limit[] {
    for (const limit of this.limits) {
      const limits: Limit[] = limit.getAgeGroupLimits(ageGroup)
      if (limits.length > 0) {
        return limits
      }
    }
    return []
  }
  public getDailyLimits(): Limit[] {
    for (const limit of this.limits) {
      const limits: Limit[] = limit.getDailyLimits()
      if (limits.length > 0) {
        return limits
      }
    }
    return []
  }
}

export function makeSeanceLimits(jsonData: any = null): SeanceLimits {
  if (!jsonData) {
    jsonData = []
  }
  const seanceLimits = jsonData
  return new SeanceLimits(
    seanceLimits.map((elt: any) => makeSeanceLimit(elt))
  )
}

export class UserMessage {
  constructor(
    public id: number,
    public text: string,
    public youthHomeNumber: number,
    public order: number,
    public openingAt: string | null,
    public closingAt: string | null
  ) {
  }
}

export function makeUserMessage(jsonData: any = null): UserMessage {
  if (!jsonData) {
    jsonData = {}
  }
  return new UserMessage(
    jsonData.id || 0,
    jsonData.text,
    jsonData.youth_home_number || 0,
    jsonData.order || 0,
    jsonData.opening_at ? jsonData.opening_at : null,
    jsonData.closing_at ? jsonData.closing_at : null
  )
}

export class DayTimes {
  constructor(
    public morning: number = 0,
    public lunch: number = 0,
    public afternoon: number = 0,
    public evening: number = 0,
    public picnic: number = 0
  ) {
  }
}

export function getSelectedDayTimes(elt: Seance|SeanceTemplate): DayTime[] {
  const allDayTimes = getDayTimes()
  const selectedDayTimes = []
  for (const dayTime of allDayTimes) {
    switch (dayTime.id) {
      case DayTimeValues.Morning:
        if (elt.morning) {
          selectedDayTimes.push(dayTime)
        }
        break
      case DayTimeValues.Lunch:
        if (elt.lunch) {
          selectedDayTimes.push(dayTime)
        }
        break
      case DayTimeValues.Picnic:
        if (elt.picnic) {
          selectedDayTimes.push(dayTime)
        }
        break
      case DayTimeValues.Afternoon:
        if (elt.afternoon) {
          selectedDayTimes.push(dayTime)
        }
        break
      case DayTimeValues.Evening:
        if (elt.evening) {
          selectedDayTimes.push(dayTime)
        }
        break
    }
  }
  return selectedDayTimes
}

export function isDayTimeSelected(value: DayTimeValues, dayTimes: DayTime[]): boolean {
  return dayTimes.map(elt => elt.id).indexOf(value) >= 0
}

export function getInscriptionsDayTimes(inscriptions: SeanceInscription[]): DayTimes {
  const dayTimes = new DayTimes()
  for (const inscription of inscriptions.filter(inscription => !inscription.absence)) {
    if (inscription.seance.morning) {
      dayTimes.morning += inscription.multiplier
    }
    if (inscription.seance.lunch && !inscription.seance.picnic) {
      dayTimes.lunch += inscription.multiplier
    }
    if (inscription.seance.lunch && inscription.seance.picnic) {
      dayTimes.picnic += inscription.multiplier
    }
    if (inscription.seance.afternoon) {
      dayTimes.afternoon += inscription.multiplier
    }
    if (inscription.seance.evening) {
      dayTimes.evening += inscription.multiplier
    }
  }
  return dayTimes
}

export class SeanceInscriptionCounter {
  constructor(
    public ageGroupId: number,
    public morning: number,
    public lunch: number,
    public afternoon: number
  ) {
  }
}

export function makeSeanceInscriptionCounter(jsonData: any = null): SeanceInscriptionCounter {
  if (!jsonData) {
    jsonData = {}
  }
  return new SeanceInscriptionCounter(
    jsonData.age_group,
    jsonData.morning,
    jsonData.lunch,
    jsonData.afternoon
  )
}

export class SeanceInscriptionLimit {
  constructor(
    public seanceId: number,
    public limits: SeanceLimit[],
    public seanceCounter: SeanceInscriptionCounter,
    public dailyCounter: SeanceInscriptionCounter,
    public excursionCounter: SeanceInscriptionCounter
  ) {
  }
}

export function makeSeanceInscriptionLimit(jsonData: any = null): SeanceInscriptionLimit {
  if (!jsonData) {
    jsonData = {}
  }
  return new SeanceInscriptionLimit(
    jsonData.seance_id,
    jsonData.limits.map((elt: any) => makeSeanceLimit(elt)),
    jsonData.seance_counter.map((elt: any) => makeSeanceInscriptionCounter(elt)),
    jsonData.daily_counter.map((elt: any) => makeSeanceInscriptionCounter(elt)),
    jsonData.excursion_counter.map((elt: any) => makeSeanceInscriptionCounter(elt))
  )
}

export class ListingPresenceItem {
  constructor(
    public id: number,
    public label: string,
    public labelPdf: string,
    public codes: string,
    public order: number
  ) {
  }

  public isPresent(seance: EntitySeance): boolean {
    const codes: string[] = this.codes.split(';')
    return existsIn([seance.getCodeName()], codes)
  }

  public shortLabel(): string {
    if (this.labelPdf) {
      return this.labelPdf
    }
    return this.label.substring(0, 3)
  }
}

export function makeListingPresenceItem(jsonData: any = null): ListingPresenceItem {
  if (!jsonData) {
    jsonData = {}
  }
  return new ListingPresenceItem(
    jsonData.id || 0,
    jsonData.label || '',
    jsonData.label_pdf || '',
    jsonData.codes || '',
    jsonData.order || 0
  )
}

export class DayListOptions {
  constructor(
    public id: number,
    public hideChildWarning: boolean,
    public hideIndividualAbout: boolean,
    public showNotes: boolean,
    public handicapFilter: boolean
  ) {
  }
}

export function makeDayListOptions(jsonData: any = null): DayListOptions {
  if (!jsonData) {
    jsonData = {}
  }
  return new DayListOptions(
    jsonData.id || 0,
    !!jsonData['hide_child_warning'],
    !!jsonData['hide_individual_about'],
    !!jsonData['show_notes'],
    !!jsonData['handicap_filter']
  )
}

export class YouthHomeSynthesis {
  constructor(
    public id: number,
    public name: string,
    public numbers: number[],
    public youthHomes: number[],
    public activity: number,
    public backgroundColor: string,
    public textColor: string
  ) {
  }

  public getHeaderStyle() {
    return calcHeaderStyle([this])
  }
}

export function makeYouthHomeSynthesis(jsonData: any = null): YouthHomeSynthesis {
  if (!jsonData) {
    jsonData = {}
  }
  return new YouthHomeSynthesis(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.numbers || [],
    jsonData.youth_homes || [],
    jsonData.activity || 0,
    jsonData.background_color || '',
    jsonData.text_color || ''
  )
}

export class SeancesConfigAgeGroups {
  constructor(
    public globalAgeGroups: AgeGroup[],
    public periodAgeGroups: Map<number, AgeGroup[]>,
    public dateAgeGroups: Map<string, AgeGroup[]>,
    public seanceAgeGroups: Map<number, AgeGroup[]>,
    public excursionAgeGroups: Map<string, AgeGroup[]>
  ) {
  }

  public hasSeanceAgeGroups(seance: Seance): boolean {
    if (seance.isExcursion()) {
      const seanceDate = dateToString(seance.date, 'YYYY-MM-DD')
      const key = seanceDate + ':' + seance.name
      return this.excursionAgeGroups.has(key)
    } else {
      return this.seanceAgeGroups.has(seance.id)
    }
  }

  public hasDateAgeGroups(date: any): boolean {
    const seanceDate = dateToString(date, 'YYYY-MM-DD')
    return this.dateAgeGroups.has(seanceDate)
  }

  public getSeanceAgeGroups(seance: Seance): AgeGroup[] {
    if (seance.isExcursion()) {
      const seanceDate = dateToString(seance.date, 'YYYY-MM-DD')
      const key = seanceDate + ':' + seance.name
      if (this.excursionAgeGroups.has(key)) {
        return this.excursionAgeGroups.get(key) || []
      }
    }
    if (this.seanceAgeGroups.has(seance.id)) {
      return this.seanceAgeGroups.get(seance.id) || []
    }
    return []
  }

  public getDateAgeGroups(date: any): AgeGroup[] {
    const seanceDate = dateToString(date, 'YYYY-MM-DD')
    if (this.dateAgeGroups.has(seanceDate)) {
      return this.dateAgeGroups.get(seanceDate) || []
    }
    return []
  }

  public hasPeriodAgeGroups(seancePeriod: SeancePeriod): boolean {
    return this.periodAgeGroups.has(seancePeriod.id)
  }

  public getPeriodAgeGroups(seancePeriod: SeancePeriod): AgeGroup[] {
    if (this.periodAgeGroups.has(seancePeriod.id)) {
      return this.periodAgeGroups.get(seancePeriod.id) || []
    }
    return []
  }

  public getGlobalAgeGroups(): AgeGroup[] {
    return this.globalAgeGroups
  }

  public getLimitAgeGroups(limit: SeanceLimit): AgeGroup[] {
    if (limit.limitType === LimitType.LIMIT_EXCURSION) {
      const key = limit.day + ':' + limit.seanceName
      const groups = this.excursionAgeGroups.get(key) || []
      if (groups.length > 0) {
        return groups
      }
    } else {
      if (limit.limitType === LimitType.LIMIT_SEANCE) {
        const groups = this.seanceAgeGroups.get(limit.seanceId) || []
        if (groups.length > 0) {
          return groups
        }
      }
      if (limit.limitType === LimitType.LIMIT_DAY) {
        const groups = this.dateAgeGroups.get(limit.day) || []
        if (groups.length > 0) {
          return groups
        }
      }
    }
    const groups = this.periodAgeGroups.get(limit.periodId) || []
    if (groups.length > 0) {
      return groups
    }
    return this.globalAgeGroups
  }
}

export function makeSeancesConfigAgeGroups(jsonData: any = null): SeancesConfigAgeGroups {
  if (!jsonData) {
    jsonData = {}
  }
  let globalAgeGroups = jsonData.global_age_groups || []
  let periodAgeGroups = jsonData.period_age_groups || {}
  let periodAgeGroupsMap = new Map()
  for (const period of Object.keys(periodAgeGroups)) {
    const periodId = +period
    const groups = periodAgeGroups[period].map(makeAgeGroup)
    periodAgeGroupsMap.set(periodId, groups)
  }
  let dateAgeGroups = jsonData.date_age_groups || []
  let dateAgeGroupsMap = new Map()
  for (const date of Object.keys(dateAgeGroups)) {
    const groups = dateAgeGroups[date].map(makeAgeGroup)
    dateAgeGroupsMap.set(date, groups)
  }
  let seanceAgeGroups = jsonData.seance_age_groups || []
  let seanceAgeGroupsMap = new Map()
  for (const seance of Object.keys(seanceAgeGroups)) {
    const seanceId = +seance
    const groups = seanceAgeGroups[seanceId].map(makeAgeGroup)
    seanceAgeGroupsMap.set(seanceId, groups)
  }
  let excursionAgeGroups = jsonData.excursion_age_groups || []
  let excursionAgeGroupsMap = new Map()
  for (const key of Object.keys(excursionAgeGroups)) {
    const groups = excursionAgeGroups[key].map(makeAgeGroup)
    excursionAgeGroupsMap.set(key, groups)
  }
  return new SeancesConfigAgeGroups(
    globalAgeGroups.map(makeAgeGroup),
    periodAgeGroupsMap,
    dateAgeGroupsMap,
    seanceAgeGroupsMap,
    excursionAgeGroupsMap
  )
}
