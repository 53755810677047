<template>
  <span class="refund-button" v-if="availableCredits.length && hasPerm('payments.add_credit')">
    <b-modal dialog-class="modal-xl"
      :id="id"
      cancel-title="Annuler"
      :ok-disabled="isRefundDisabled()"
      @ok.prevent="onRefund"
      ok-variant="primary"
      ok-title="Ok"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-money-bill"></i> Saisie d'un remboursement</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col cols="8">
          <b-row class="" v-if="credits.length">
            <b-col>
              <b-form-group
                id="amount-group"
                label="Montant"
                label-for="amount"
                description="Montant à rembourser"
              >
                <b-form-input id="amount" :value="selectedRemainingAmount | currency" disabled></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="donation-group"
                label-for="donation"
                description="La famille souhaite vous faire un don d'une partie de son avoir"
              >
                <div class="donate-links">
                  <b>Don</b>
                  <a href @click.prevent="donateAll" class="small2">Tout</a>
                  <a href @click.prevent="donation = 0" class="small2">Rien</a>
                </div>
                <b-form-input
                  type="number" min=0 step="1" :max="selectedRemainingAmount" id="amount" v-model="donation"
                >
                </b-form-input>
                <div v-if="isDonationInvalid" class="field-error">
                  Le montant doit être compris entre 0 et {{ selectedRemainingAmount|currency }}
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="total-group"
                label="Montant remboursé"
                label-for="total"
                description="Montant effectivement remboursé à la famille"
              >
                <b-form-input id="total" :value="total | currency" disabled></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="payment-modes-group"
                label="Mode"
                label-for="payment-modes"
                description="Mode de paiement"
              >
                <b-form-select
                  id="payment-modes"
                  v-model="selectedRefundMode"
                  :required="total > 0"
                  :disabled="total === 0"
                  >
                  <b-form-select-option
                    :value="item"
                    v-for="item in refundModes"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="bank-group"
                label="Banque"
                label-for="bank-name"
              >
                <b-form-input
                  id="bank-name"
                  v-model="bankName"
                  :required="selectedRefundMode.bank && selectedRefundMode.isNumberRequired"
                  :disabled="!selectedRefundMode.bank"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="bank-number-group"
                label="Numéro"
                label-for="bank-number"
                :description="selectedRefundMode.isNumberRequired ? 'requis' : ''"
              >
                <b-form-input
                  id="bank-number"
                  v-model="bankNumber"
                  :required="selectedRefundMode.isNumberRequired"
                  :disabled="!selectedRefundMode.bank && !selectedRefundMode.isNumberRequired"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="comments-group"
                label="Commentaires"
                label-for="comments"
              >
                <b-form-input id="comments" v-model="comments"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <div class="modal-line">
            {{ entity.name }}
          </div>
          <div class="modal-line">
            <div><b>Avoirs en cours</b></div>
            <check-box-select
              :initial-value="availableCredits"
              :choices="availableCredits"
              :name-callback="getCreditName"
              @changed="onSelectedCreditsChanged($event)"
            ></check-box-select>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'
import { makePaymentMode } from '@/types/payments'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import { sum } from '@/utils/math'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'

export default {
  name: 'refund-modal',
  components: { CheckBoxSelect, },
  mixins: [BackendMixin],
  props: {
    credits: Array,
    entity: Object,
    cssClass: {
      type: String,
      defaultValue: '',
    },
    changer: {
      type: Number,
      defaultValue: 0,
    },
    id: String,
  },
  data() {
    return {
      randomId: getRandomId(),
      paymentModes: [],
      selectedRefundMode: makePaymentMode(),
      comments: '',
      bankName: '',
      bankNumber: '',
      errorText: '',
      donation: 0,
      selectedCredits: [],
    }
  },
  computed: {
    availableCredits() {
      return this.credits.filter(elt => elt.isAvailable())
    },
    total() {
      return this.selectedRemainingAmount - this.donation
    },
    isDonationInvalid() {
      if ((this.donation < 0) || (this.total < 0)) {
        return true
      }
      return false
    },
    selectedRemainingAmount() {
      return sum(this.selectedCredits.map(elt => elt.remainingAmount))
    },
    refundModes() {
      return this.paymentModes.filter(elt => elt.canRefund)
    },
  },
  watch: {
    changer: function(value) {
      if (value) {
        this.init()
      }
    },
    credits: function() {
      this.selectedCredits = this.availableCredits
      this.donation = 0
    },
    availableCredits: function() {
      this.selectedCredits = this.availableCredits
      this.donation = 0
    },
    selectedCredits: function() {
      if (this.donation > this.selectedRemainingAmount) {
        this.donation = Math.round(this.selectedRemainingAmount * 100) / 100
      }
    },
    total: function() {
      if (this.total === 0) {
        this.selectedRefundMode = makePaymentMode()
      }
    },
    selectedRefundMode: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      this.selectedCredits = this.availableCredits
      this.donation = 0
      this.loadPaymentModes()
    },
    isRefundDisabled() {
      if (this.total === 0) {
        return this.donation === 0 // autorise le don total sans remboursement
      } else {
        if (this.selectedRefundMode.id === 0) {
          return true
        } else {
          if (this.isDonationInvalid) {
            return true
          }
          let isOk = true
          if (this.selectedRefundMode.bank) {
            isOk = this.bankName.length > 0
          }
          if (isOk && this.selectedRefundMode.isNumberRequired) {
            isOk = this.bankNumber.length > 0
          }
          if (!isOk) {
            return true
          }
        }
        return false
      }
    },
    async onRefund() {
      this.errorText = ''
      try {
        const url = '/api/entity/' + this.entity.id + '/refund-credit/'
        const backendApi = new BackendApi('post', url)
        let data = {
          credits: this.selectedCredits.map(elt => elt.id),
          refund_mode: this.selectedRefundMode.id,
          comments: this.comments,
          bank_name: this.bankName,
          bank_number: this.bankNumber,
          donation: this.donation,
          // amount: '', TODO : montant différent que la somme des avoirs
        }
        await backendApi.callApi(data)
        this.$bvModal.hide(this.id)
        this.$emit('refund')
      } catch (err) {
        this.errorText = this.getErrorText(err)
        this.errorText = this.getErrorText(err)
        this.addError(this.getErrorText(err))
      }
    },
    async loadPaymentModes() {
      try {
        let url = '/api/payment-modes/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.paymentModes = resp.data.map(elt => makePaymentMode(elt))
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    getCreditName(credit) {
      return credit.getName()
    },
    onSelectedCreditsChanged(selected) {
      this.selectedCredits = selected.choices
    },
    donateAll() {
      this.donation = Math.round(this.selectedRemainingAmount * 100) / 100
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style scoped lang="less">
  .modal-line {
    padding-bottom: 5px;
    border-bottom: solid 1px #f0f0f0;
    margin-bottom: 5px;
  }
  .modal-line:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .field-error {
    color: #cc3700;
    background: #e0e0e0;
    padding: 2px;
    font-size: 12px;
    margin: 5px 0;
  }
  .donate-links a{
    margin-left: 5px;
    display: inline-block;
  }
</style>
