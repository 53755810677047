<template>
  <div class="tabs-bar" :class="{ disabled: !enabled, inline: inline, }">
    <a v-for="tab in tabs" v-bind:key="tab.name"
      :href="'?tab=' + tab.name"
      @click="onSelectTab($event, tab)"
      :class="getTabClass(tab)"
    >
      <i :class="tab.icon" v-if="tab.icon"></i>
      {{ tab.label }}
    </a>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
// import TabItem from '@/types/tabs'

export default {
  name: 'TabsBar',
  props: {
    tabs: {
      type: Array,
      required: true,
      validator: tabs => {
        // for (let tab of tabs) {
        //   let isTabItem = tab instanceof TabItem
        //   if (!isTabItem) {
        //     return false
        //   }
        // }
        return true
      },
    },
    active: Object,
    enabled: {
      type: Boolean,
      defaultValue: true,
    },
    secondary: {
      type: Boolean,
      defaultValue: false,
    },
    inline: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    onSelectTab(event, tab) {
      event.preventDefault()
      if (this.enabled) {
        this.$emit('change', tab)
      }
    },
    getTabClass(tab) {
      let cssClass = this.secondary ? 'secondary' : 'primary'
      if (this.active && tab && (this.active.name === tab.name)) {
        cssClass += ' active'
      }
      if (tab.cssStyle) {
        cssClass += ' ' + tab.cssStyle
      }
      return cssClass
    },
  },
}
</script>
<style scoped lang="less">
  .tabs-bar.inline {
    display: inline-block;
  }
  .tabs-bar a{
    display: inline-block;
  }
  .tabs-bar a.primary {
    padding: 5px 15px;
    border: solid 1px #454851;
    color: #454851 !important;
    background: #fff;
    margin-right: 10px;
  }
  .tabs-bar a.secondary{
    padding: 2px 10px;
    border: solid 1px #ccc;
    color: #454851 !important;
    background: transparent;
    margin-right: 5px;
  }
  .tabs-bar.disabled a{
    color: #ccc !important;
    cursor: not-allowed;
  }
  .tabs-bar.disabled a.primary{
    cursor: not-allowed;
    border-color: #ccc;
  }
  .tabs-bar.disabled a.active.primary,
  .tabs-bar a.active.primary{
    color: #fff !important;
    background: #454851 !important;
    border: solid 1px #454851;
  }
  .tabs-bar a.active.secondary{
    font-weight: bold;
    background: #2c2c2c !important;
    color: #fff !important;
  }
  .tabs-bar a.highlight {
    background: #628dae !important;
    color: #fff !important;
  }
 .tabs-bar a.active.highlight {
   background: #4d85af !important;
   color: #fff !important;
  }
  .tabs-bar a.disabled {
    color: #888 !important;
  }
  .tabs-bar a.active.disabled {
  }
  .dark-mode {
    .tabs-bar a.primary{
      border: solid 1px #454851;
      color: #fff !important;
      background: #222;
    }
    .tabs-bar a.secondary{
      border: solid 1px #ccc;
      background: #444;
      color: #ccc !important;
    }
    .tabs-bar.disabled a{
      color: #ccc !important;
    }
    .tabs-bar.disabled a.active.primary,
    .tabs-bar a.active.primary{
      color: #fff !important;
      background: #454851 !important;
      border: solid 1px #454851;
    }
    .tabs-bar a.active.secondary{
      font-weight: bold;
      background: #2c2c2c !important;
      color: #fff !important;
    }
    .tabs-bar a.highlight {
      background: #628dae !important;
      color: #fff !important;
    }
   .tabs-bar a.active.highlight {
     background: #4d85af !important;
     color: #fff !important;
    }
    .tabs-bar a.disabled {
      color: #888 !important;
    }
  }
</style>
